
import { useStore } from '@/store'; 
import { GET_VIEW_MAP, GET_VIEW_MAP_SENSORS } from '@/store/action-types';
import { defineComponent, onMounted, computed, ref, onUnmounted } from 'vue';
import SensorMap from '@/components/SensorMap.vue';

export default defineComponent({
    name: 'ViewMap',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        SensorMap
    },
    setup(props) {
        const store = useStore();
        const map = ref<HTMLDivElement | null>();
        const intervalTimer = ref<number>();

        store.dispatch(GET_VIEW_MAP, props.id);
        store.dispatch(GET_VIEW_MAP_SENSORS);

        const structure = computed(() => store.state.map.structure);
        const image = computed(() => {
            if(structure.value) {
                return `${process.env.VUE_APP_SERVER_URI}/images/${structure.value.file}`;
            }

            return '';
        });

        const containerWidth = computed(() => map.value?.getBoundingClientRect().width || 0);
        const containerHeight = computed(() => map.value?.getBoundingClientRect().height || 0);

        onUnmounted(() => {
            clearInterval(intervalTimer.value);
        })

        return {
            viewMap: computed(() => store.state.map.map),
            structure,
            sensors: computed(() => store.state.map.sensors?.filter(sensor => sensor.position?.show)),
            endpointSensors: computed(() => store.state.map.endpointSensors),
            image,
            map,
            containerHeight,
            containerWidth
        }
    }
});
