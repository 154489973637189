
import { defineComponent, ref } from 'vue';
import Modal from '@/components/Modal.vue';
import SelectSensor from '../Sensor/SelectSensor.vue';
import SelectComparator from '../Comparator/SelectComparator.vue';
import SelectOperator from '../../Operator/SelectOperator.vue';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Structure from '@/interfaces/Structure';
import { Rule } from '@/interfaces/Trigger';
library.add(faClipboard);

export default defineComponent({
    name: 'ModalRule',
    emits: ['closeModalClick', 'okModalClick'],
    props: {
        isActive: {
            type: Boolean
        }
    },
    components: {
        Modal,
        SelectSensor,
        SelectComparator,
        SelectOperator
    },
    setup(props, { emit }) {
        const selectedSensor = ref<Structure | undefined>();
        const selectedComparator = ref('gt');
        const selectedOperator = ref<string | undefined>();
        const ruleValue = ref(0);

        const closeModal = () => {
            clearFields();
            emit('closeModalClick');
        };

        const selectSensor = (sensor: Structure) => {
            selectedSensor.value = sensor;
        };

        const selectComparator = (option: string) => {
            selectedComparator.value = option;
        };

        const selectOperator = (option: string) => {
            selectedOperator.value = option;
        };

        const clearFields = () => {
            selectedSensor.value = undefined;
            selectedOperator.value = undefined;
            selectedComparator.value = 'gt';
            ruleValue.value = 0;
        };

        const saveRule = () => {
            const rule: Rule = {
                sensorId: selectedSensor.value!.id,
                comparator: selectedComparator.value,
                value: ruleValue.value,
                operator: selectedOperator.value
            };

            clearFields();
            emit('okModalClick', rule);
        };

        return {
            closeModal,
            selectSensor,
            selectedSensor,
            selectedComparator,
            selectedOperator,
            ruleValue,
            selectComparator,
            selectOperator,
            saveRule
        }
    }
});
