import { Gateway } from "@/interfaces/Gateway";
import { Module } from "vuex";
import { State } from "..";
import { GET_GATEWAYS } from "../action-types";
import http from "@/http";
import { SET_GATEWAYS } from "../mutation-types";

export interface GatewayState {
    gateways: Gateway[]
}

export const gateway: Module<GatewayState, State> = {
    mutations: {
        [SET_GATEWAYS](state, gateways: Gateway[]) {
            state.gateways = gateways;
        }
    },
    actions: {
        async [GET_GATEWAYS]({ commit}) {
            const resposta = await http.smartCity.get('/gateways/all');
            commit(SET_GATEWAYS, resposta.data['data']);
        }
    }
}