import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table is-fullwidth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keys, (key) => {
          return (_openBlock(), _createElementBlock("th", { key: key }, _toDisplayString(key), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (option, index) => {
        return (_openBlock(), _createElementBlock("tr", { key: index }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option, (value, key) => {
            return (_openBlock(), _createElementBlock("td", { key: key }, _toDisplayString(value), 1))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}