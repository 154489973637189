
import { computed, defineComponent } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import AlarmCard from '@/components/alarm/AlarmCard.vue';
import { useStore } from '@/store';
import { GET_ALARM_HISTORY } from '@/store/action-types';
import { usePermissions } from '@/composables/UsePermissions';

export default defineComponent({
    name: 'Alarms',
    components: {
        TitleBar,
        PageHeader,
        AlarmCard
    },
    setup() {
        const store = useStore();
        const { root } = usePermissions();
        store.dispatch(GET_ALARM_HISTORY, root.value);

        return {
            alarms: computed(() => store.state.alarm.historyAlarms)
        }
    }
    
});
