import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-127f05d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sensors-body" }
const _hoisted_2 = { class: "endpoint-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_SensorCard = _resolveComponent("SensorCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitleBar, { title: "Sensores" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sensors, (sensor) => {
          return (_openBlock(), _createBlock(_component_SensorCard, {
            key: sensor.id,
            sensor: sensor
          }, null, 8, ["sensor"]))
        }), 128))
      ])
    ])
  ], 64))
}