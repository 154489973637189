
import { useStore } from '@/store';
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watchEffect } from 'vue';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCheck);

export default defineComponent({
    name: 'SelectUser',
    emits: ['onSelectUsers'],
    props: {
        users: {
            type: Object as PropType<string[] | undefined>,
            required: true
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const formUsers = ref<string[] | undefined>([]);
        const selectUser = ref<HTMLDivElement | null>(null);
        const optionUser = ref<HTMLDivElement | null>(null);

        const showOptions = () => {
            if(optionUser.value) {
                optionUser.value.style.display = 'block';
            }
        };

        const closeOptions = () => {
            if(optionUser.value) {
                optionUser.value.style.display = 'none';
            }
        };

        const showDropDown = () => {
            if(!selectUser.value || !optionUser.value) {
                return;
            }

            const x = selectUser.value.offsetLeft;
            const y = selectUser.value.offsetTop + selectUser.value.getBoundingClientRect().height;
            const width = selectUser.value.getBoundingClientRect().width;

            optionUser.value.style.left = `${x}px`;
            optionUser.value.style.top = `${y}px`;
            optionUser.value.style.width = `${width}px`;

            showOptions();
        };

        const selectUsers = (users: string[] | undefined) => {
            emit('onSelectUsers', users);
            closeOptions();
        };

        const windowClick = (event: MouseEvent) => {
            if(!selectUser.value?.contains(event.target as Node) && !optionUser.value?.contains(event.target as Node)) {
                closeOptions();
            }
        };

        watchEffect(() => {
            if(props.users && props.users.length > 0) {
                formUsers.value = props.users;
            }
        });

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        return {
            formUsers,
            selectUser,
            optionUser,
            showDropDown,
            selectUsers,
            selectedUsers: computed(() => store.state.user.users.filter(item => props.users?.includes(item.email)).map(item => item.username).join(', ')),
            usersList: computed(() => store.state.user.users)
        }
    }
});
