
import { computed, defineComponent } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import SensorCard from '@/components/SensorCard.vue';
import { useStore } from '@/store';
import { GET_ENDPOINTS, GET_SENSORS } from '@/store/action-types';
import { usePermissions } from '@/composables/UsePermissions';

export default defineComponent({
    name: 'Sensors',
    components: {
        TitleBar,
        PageHeader,
        SensorCard
    },
    setup() {
        const store = useStore();
        const { isMaster } = usePermissions();
        store.dispatch(GET_SENSORS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });
        store.dispatch(GET_ENDPOINTS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });

        return {
            sensors: computed(() => store.state.sensor.sensors)
        }
    }
});
