import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c48c5cc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-map" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorMap = _resolveComponent("SensorMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.structure?.name), 1),
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "map",
          class: "map",
          style: _normalizeStyle({ backgroundImage: `url(${_ctx.image})`})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sensors, (sensor) => {
            return (_openBlock(), _createBlock(_component_SensorMap, {
              key: sensor.id,
              structure: sensor,
              style: _normalizeStyle({ left: `${sensor.position!.left * _ctx.containerWidth}px`, top: `${sensor.position!.top * _ctx.containerHeight}px`})
            }, null, 8, ["structure", "style"]))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ]))
}