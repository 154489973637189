
import Endpoint from '@/interfaces/Endpoint';
import { defineComponent, PropType } from 'vue';
import TitleCard from '@/components/TitleCard.vue';
import EndpointItem from './EndpointItem.vue';
import { faTowerBroadcast, faTag, faSatelliteDish, faBullseye, faGauge, faSignal, faAlignCenter, faPlug } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faTowerBroadcast, faTag, faSatelliteDish, faBullseye, faGauge, faSignal, faAlignCenter, faPlug);

export default defineComponent({
    name: 'EndpointCard',
    props: {
        endpoint: {
            type: Object as PropType<Endpoint>,
            required: true
        }
    }
    ,components: {
        TitleCard,
        EndpointItem
    }
});
