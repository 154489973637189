import http from "@/http";
import { ViewMap } from "@/interfaces/ViewMap";
import Structure from "@/interfaces/Structure";
import { State } from "@/store";
import { CREATE_VIEW_MAP, GET_VIEW_MAP, GET_VIEW_MAP_SENSORS } from "@/store/action-types";
import { Module } from "vuex";
import { DEFINE_VIEW_MAP, DEFINE_VIEW_MAP_STRUCTURE, DEFINE_VIEW_MAP_SENSORS, DEFINE_VIEW_MAP_ENDPOINT_SENSORS } from "@/store/mutation-types";
import Sensor from "@/interfaces/Sensor";

export interface MapState {
    structure: Structure;
    sensors: Structure[];
    endpointSensors: Sensor[];
    map: ViewMap;
}

export const map: Module<MapState, State> = {
    mutations: {
        [DEFINE_VIEW_MAP](state, viewMap: ViewMap) {
            state.map = viewMap;
        },
        [DEFINE_VIEW_MAP_STRUCTURE](state, structure: Structure) {
            state.structure = structure;
        },
        [DEFINE_VIEW_MAP_SENSORS](state, sensors: Structure[]) {
            state.sensors = sensors;
        },
        [DEFINE_VIEW_MAP_ENDPOINT_SENSORS](state, sensors: Sensor[]) {
            state.endpointSensors = sensors;
        }
    },
    actions: {
        async [CREATE_VIEW_MAP]({ commit }, data: any) {
            const resposta = await http.smartCity.post('/view-map', data);
            const viewMap: ViewMap = resposta.data['data'];
            return viewMap.id;
        },
        async [GET_VIEW_MAP]({ commit }, id: string) {
            const resposta = await http.smartCity.get(`/view-map/${id}`);
            const viewMap: ViewMap = resposta.data['data'];
            const structureResponse = await http.smartCity.get(`/structures/${viewMap.structure}`);
            const structure: Structure = structureResponse.data['data'];
            const sensorResponse = await http.smartCity.get(`/structures/parent/${viewMap.structure}`);
            const sensors: Structure[] = sensorResponse.data['data'];
            commit(DEFINE_VIEW_MAP, viewMap);
            commit(DEFINE_VIEW_MAP_STRUCTURE, structure);
            commit(DEFINE_VIEW_MAP_SENSORS, sensors.filter(sensor => sensor.type == 'sensor'));
        },
        async [GET_VIEW_MAP_SENSORS]({ commit }) {
            const resposta = await http.smartCity.get('/sensors/all');
            commit(DEFINE_VIEW_MAP_ENDPOINT_SENSORS, resposta.data['data']);
        }
    }
}