
import { useStore } from '@/store';
import { defineComponent, onMounted, ref, reactive, computed } from 'vue';
import Structure from '@/interfaces/Structure';
import StructureItem from './StructureItem.vue';

export default defineComponent({
    name: 'StructureList',
    emits: ['onItemSelected'],
    components: {
        StructureItem
    },

    setup(prop, { emit }) {
        const store = useStore();
        const selectedTenant = ref('');

        const selectItem = (structure: Structure) => {
            emit('onItemSelected', structure)
        };


        return {
            selectItem,
            structures: computed(() => store.state.structure.structures)
        }
    }
});
