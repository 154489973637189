
import { defineComponent } from 'vue';
import { faThermometerHalf, faDroplet, faHouseFloodWater, faToggleOff, faFaucetDrip } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faThermometerHalf, faDroplet, faHouseFloodWater, faToggleOff, faFaucetDrip);

export default defineComponent({
    name: 'SensorType',
    emits: ['onTypeSelect'],
    props: {
        type: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const selectType = () => {
            emit('onTypeSelect', props.type);
        };

        return {
            selectType
        }
    }
});
