
import { useStore } from '@/store';
import { GET_USERS, GET_USER_TENANT, USER_AUTHENTICATE } from '@/store/action-types';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePermissions } from '@/composables/UsePermissions';
import { useStructures } from '@/composables/UseStrucutures';
import { useTenants } from '@/composables/UseTenants';
import AuthError from '@/components/AuthError.vue';
import PermissionError from '@/components/PermissionError.vue';

export default defineComponent({
    name: 'Login',
    components: {
        AuthError,
        PermissionError
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loginError = ref(false);
        const permissionError = ref(false);
        const form = ref({
            username: '',
            password: ''
        });
        const { root, hasAccess, hasPermission, isMaster } = usePermissions();

        const closeErrorMessage = () => {
            loginError.value = false;
        }

        const closePermissionErrorMessage = () => {
            permissionError.value = false;
        }

        const onSubmit = async () => {
            await store.dispatch(USER_AUTHENTICATE, form.value);
            if(hasAccess.value) {
                if(!hasPermission.value) {
                    permissionError.value = true;
                    return;
                }

                if(!isMaster.value) {
                    store.dispatch(GET_USER_TENANT, store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.structure);
                }
                
                router.push(`/dashboard/structure/${root.value}`)

            } else {
                loginError.value = true;
            }
        };

        return {
            form,
            onSubmit,
            loginError,
            closeErrorMessage,
            permissionError,
            closePermissionErrorMessage,
        }
    }
});
