import { createApp } from 'vue'
import App from './App.vue' 
import router from './router';
import { key, store } from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

createApp(App)
    .use(router)
    .use(store, key)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
    .component('font-awesome-layers-text', FontAwesomeLayersText)
    .mount('#app')
