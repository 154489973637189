import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fa135d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sensor-card" }
const _hoisted_2 = { class: "sensor-item" }
const _hoisted_3 = { class: "item-title" }
const _hoisted_4 = { class: "item-icon" }
const _hoisted_5 = { class: "item-text" }
const _hoisted_6 = { class: "sensor-item" }
const _hoisted_7 = { class: "item-title" }
const _hoisted_8 = { class: "item-icon" }
const _hoisted_9 = { class: "item-text" }
const _hoisted_10 = { class: "sensor-measure" }
const _hoisted_11 = { class: "sensor-message" }
const _hoisted_12 = { class: "sensor-value" }
const _hoisted_13 = { class: "sensor-unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorIcon = _resolveComponent("SensorIcon")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleCard, {
      title: _ctx.sensor.name
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_SensorIcon, {
          type: _ctx.sensor.type
        }, null, 8, ["type"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tower-broadcast" })
        ]),
        _createTextVNode(" Endpoint ")
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.endpoint), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-calendar-days" })
        ]),
        _createTextVNode(" Última Atualização ")
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.lastMessage), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.sensorValue), 1),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.sensor.unit), 1)
      ])
    ])
  ]))
}