import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", {
    class: "icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    (_ctx.checked)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: "fa-solid fa-square-xmark"
        }))
      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: "fa-regular fa-square"
        }))
  ]))
}