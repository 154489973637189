import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ba12927"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  ref: "options",
  class: "options"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "select",
      class: "select-element",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showOptionsDropDown && _ctx.showOptionsDropDown(...args)))
    }, [
      (_ctx.tenant)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.selectedTenant), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, "Escolha..."))
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tenant.id,
          class: "option",
          onClick: ($event: any) => (_ctx.optionClick(tenant.id))
        }, [
          _createElementVNode("span", null, _toDisplayString(tenant.name), 1)
        ], 8, _hoisted_4))
      }), 128))
    ], 512)
  ], 64))
}