import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f268409"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-item" }
const _hoisted_2 = { class: "item-title" }
const _hoisted_3 = { class: "text-icon" }
const _hoisted_4 = { class: "item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value), 1)
  ]))
}