
import Structure from '@/interfaces/Structure';
import SensorIcon from './SensorIcon.vue';
import { computed, defineComponent, PropType, ref } from 'vue';
import { faFolder, faMapLocationDot, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faFolder, faMapLocationDot, faTrashCan);
export default defineComponent({
    name: 'StructureCard',
    emits: ['onClick', 'onMapClick', 'onDeleteSensorClick'],
    components: {
        SensorIcon
    },
    props: {
        structure : {
            type: Object as PropType<Structure>,
            
        }
    },

    setup(props, { emit }) {
        const mapIcon = ref<HTMLSpanElement | undefined>();
        const removeSensor = ref<HTMLDivElement | undefined>();
        const { isMaster, isSmartAdmin } = usePermissions();

        const getCreationDate = computed(() => {
            if(props.structure) {
                let parsed = new Date(Date.parse(props.structure?.createdAt));
                return parsed.toLocaleDateString() + " " + parsed.toLocaleTimeString();
            }

            return "";
        });

        const getModificationDate = computed(() => {
            if(props.structure) {
                let parsed = new Date(Date.parse(props.structure?.modifiedAt));
                return parsed.toLocaleDateString() + " " + parsed.toLocaleTimeString();
            }

            return "";
        });

        const cardClick = (structure: Structure, event: EventTarget | null) => {
            const element = event as Node;
            
            if(mapIcon.value?.contains(element) && structure.file) {
                emit('onMapClick', structure);
            } else if (removeSensor.value?.contains(element)) {
                emit('onDeleteSensorClick', structure);
            } else {
                emit('onClick', structure);
            }
        }

        return {
            getCreationDate,
            getModificationDate,
            cardClick,
            mapIcon,
            removeSensor,
            isMaster,
            isSmartAdmin
        }
    }
});
