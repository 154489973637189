import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_font_awesome_layers = _resolveComponent("font-awesome-layers")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_font_awesome_layers, null, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-cloud-sun-rain",
          transform: "shrink-7 up-6 left-5"
        }),
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-wifi",
          transform: "shrink-7 up-6 right-7"
        }),
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-temperature-half",
          transform: "shrink-7 down-6 left-0"
        }),
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-snowflake",
          transform: "shrink-7 down-6 right-10"
        })
      ]),
      _: 1
    })
  ]))
}