
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import MonitorCard from '@/components/MonitorCard.vue';
import Modal from '@/components/Modal.vue';
import SensorType from '@/components/SensorType.vue';
import EndpointSelect from '@/components/select/EndpointSelect.vue';
import EndpointOption from '@/components/select/EndpointOption.vue';
import StructureList from '@/components/structure/StructureList.vue';
import { faList, faFilter, faTableCellsLarge, faSquareXmark, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useStore } from '@/store';
import Endpoint from '@/interfaces/Endpoint';
import Structure from '@/interfaces/Structure';
import { GET_SENSORS, GET_STRUCTURES } from '@/store/action-types';
import { usePermissions } from '@/composables/UsePermissions';
import { useStructures } from '@/composables/UseStrucutures';
library.add(faList, faFilter, faTableCellsLarge, faSquare, faSquareXmark, faFolder);

export default defineComponent({
    name: 'Monitor',
    components: {
        TitleBar,
        PageHeader,
        MonitorCard,
        Modal,
        SensorType,
        EndpointSelect,
        EndpointOption,
        StructureList
    },
    setup() {
        const store = useStore();
        const isListMode = ref(false);

        const isFilterModalOn = ref(false);
        const isFilterLocation = ref(false);
        const isFilterEndpoint = ref(false);
        const isFilterType = ref(false);

        const locationSelect = ref<HTMLDivElement | null>(null);
        const endpointSelect = ref<HTMLDivElement | null>(null);
        const sensorTypeSelect = ref<HTMLDivElement | null>(null);
        const locationOptions = ref<HTMLDivElement | null>(null);
        const endpointOptions = ref<HTMLDivElement | null>(null);
        const sensorTypeOptions = ref<HTMLDivElement | null>(null);

        const selectedSensorType = ref<string | null>(null);
        const selectedEndpoint = ref<Endpoint | null>(null);
        const selectedStructure = ref<Structure | null>(null);

        const filteredSensorType = ref<string | null>(null);
        const filteredEndpoint = ref<Endpoint | null>(null);
        const filteredStructure = ref<Structure | null>(null);

        const modal = ref<HTMLDivElement | null>(null);

        const { userRootStructure, sensorIsAccessible, structureParents } = useStructures();
        const { isMaster } = usePermissions();

        store.dispatch(GET_SENSORS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });

        store.dispatch(GET_STRUCTURES);

        const toggleFilterLocation = () => {
            isFilterLocation.value = !isFilterLocation.value;
            if(!isFilterLocation.value) {
                clearSelection(selectedStructure);
            }
        };

        const toggleFilterEndpoint = () => {
            isFilterEndpoint.value = !isFilterEndpoint.value;
            if(!isFilterEndpoint.value) {
                clearSelection(selectedEndpoint);
            }
        };

        const toggleFilterType = () => {
            isFilterType.value = !isFilterType.value;
            if(!isFilterType.value) {
                clearSelection(selectedSensorType);
            }
        }

        const showFilterModal = () => {
            if(filteredStructure.value) {
                checkOption(isFilterLocation);
                selectedStructure.value = filteredStructure.value;
            }

            if(filteredSensorType.value) {
                checkOption(isFilterType);
                selectedSensorType.value = filteredSensorType.value;
            }

            if(filteredEndpoint.value) {
                checkOption(isFilterEndpoint);
                selectedEndpoint.value = filteredEndpoint.value;
            }

            isFilterModalOn.value = true;
        };

        const hideFilterModal = () => {
            hideAllSelects();
            clearAllSelections();
            isFilterModalOn.value = false;
        };

        const changeDisplayMode = () => {
            isListMode.value = !isListMode.value;
        };

        const openSelect = (select: HTMLDivElement | null, options: HTMLDivElement | null, isFilter: boolean) => {
            if(!isFilter) {
                return;
            }

            //console.log('Modal', modal.value);

            const x = select!.getBoundingClientRect().x;
            const y = select!.getBoundingClientRect().y + select!.getBoundingClientRect().height;
            const width = select!.getBoundingClientRect().width;

            options!.style.width = `${width}px`;
            options!.style.left = `${x}px`;
            options!.style.top = `${y}px`;

            showSelect(options);
        };

        const modalFilterClick = (event: MouseEvent) => {
            const el = event.target as HTMLDivElement;
            if(!locationSelect.value?.contains(el)) {
                hideSelect(locationOptions.value);
            }
            if(!endpointSelect.value?.contains(el)) {
                hideSelect(endpointOptions.value);
            }
            if(!sensorTypeSelect.value?.contains(el)) {
                hideSelect(sensorTypeOptions.value);
            }
        };

        const hideAllSelects = () => {
            hideSelect(locationOptions.value);
            hideSelect(endpointOptions.value);
            hideSelect(sensorTypeOptions.value);
        };

        const clearAllSelections = () => {
            uncheckOption(isFilterEndpoint);
            uncheckOption(isFilterLocation);
            uncheckOption(isFilterType);
            clearSelection(selectedEndpoint);
            clearSelection(selectedStructure);
            clearSelection(selectedSensorType);
        };

        const showSelect = (options: HTMLDivElement | null) => {
            options!.style.display = 'block';
        };

        const hideSelect = (options: HTMLDivElement | null) => {
            options!.style.display = 'none';
        };

        const clearSelection = (selected: Ref) => {
            selected.value = null;
        };

        const uncheckOption = (check: Ref) => {
            check.value = false;
        };

        const checkOption = (check: Ref) => {
            check.value = true;
        };

        const selectSensorType = (sensorType: string) => {
            hideSelect(sensorTypeOptions.value);
            selectedSensorType.value = sensorType;
        };

        const selectEndpoint = (endpoint: Endpoint) => {
            hideSelect(endpointOptions.value);
            selectedEndpoint.value = endpoint;
        };

        const selectStructure = (structure: Structure) => {
            hideSelect(locationOptions.value);
            selectedStructure.value = structure;    
        };

        const applyFilter = () => {
            if(isFilterLocation.value) {
                filteredStructure.value = selectedStructure.value;
            } else {
                filteredStructure.value = null;
            }

            if(isFilterType.value) {
                filteredSensorType.value = selectedSensorType.value;
            } else {
                filteredSensorType.value = null;
            }

            if(isFilterEndpoint.value) {
                filteredEndpoint.value = selectedEndpoint.value;
            } else {
                filteredEndpoint.value = null;
            }

            hideFilterModal();
        };

        return {
            isListMode,
            changeDisplayMode,
            isFilterModalOn,
            isFilterLocation,
            isFilterEndpoint,
            isFilterType,
            toggleFilterLocation,
            toggleFilterEndpoint,
            toggleFilterType,
            showFilterModal,
            hideFilterModal,
            modalFilterClick,
            hideAllSelects,
            openSelect,
            locationSelect,
            endpointSelect,
            sensorTypeSelect,
            locationOptions,
            endpointOptions,
            sensorTypeOptions,
            modal,
            selectedSensorType,
            selectedEndpoint,
            selectedStructure,
            selectSensorType,
            selectEndpoint,
            selectStructure,
            applyFilter,
            sensors: computed(() => { 
                let sensors = store.state.structure.structures?.filter(structure => structure.type == 'sensor');

                const userRoot = store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.structure;

                if(userRoot && !isMaster.value) {
                    sensors = sensors.filter(sensor => sensorIsAccessible(sensor, userRoot));
                }

                const strucParents = structureParents(sensors[0]);

                if(!isMaster) {
                    sensors = sensors.filter(sensor => sensorIsAccessible(sensor, userRootStructure.value));
                }

                if(filteredSensorType.value) {
                    sensors = sensors?.filter(structure => structure.sensorType == filteredSensorType.value);
                }

                if(filteredStructure.value) {
                    sensors = sensors?.filter(structure => structure.parent == filteredStructure.value?.id);
                }

                if(filteredEndpoint.value) {
                    const endpointSensors = store.state.sensor.sensors.filter(sensor => sensor.endpoint == filteredEndpoint.value?.id).map(sensor => sensor.id);
                    sensors = sensors?.filter(structure => endpointSensors.includes(structure.sensor || ''));
                }

                return sensors;
            })
        }
    }
});
