import http from "@/http";
import { Trigger } from "@/interfaces/Trigger";
import { State } from "@/store";
import { CREATE_TRIGGER, GET_TRIGGERS, UPDATE_TRIGGER } from "@/store/action-types";
import { CHANGE_TRIGGER, DEFINE_TRIGGERS, LOGOUT, SAVE_TRIGGER } from "@/store/mutation-types";
import { Module } from "vuex";

export interface TriggerState {
    triggers: Trigger[];
}

export const trigger: Module<TriggerState, State> = {
    mutations: {
        [DEFINE_TRIGGERS](state, triggers: Trigger[]) {
            state.triggers = triggers;
        },
        [SAVE_TRIGGER](state, trigger: Trigger) {
            state.triggers.push(trigger);
        },
        [CHANGE_TRIGGER](state, trigger: Trigger) {
            const index = state.triggers.findIndex(t => t.id == trigger.id);
            state.triggers[index] = trigger;
        },
        [LOGOUT](state) {
            state.triggers = [];
        }
    },
    actions: {
        async [GET_TRIGGERS]({ commit }) {
            const resposta = await http.smartCity.get('/triggers/all')
            commit(DEFINE_TRIGGERS, resposta.data['data']);
        },
        async [CREATE_TRIGGER]({ commit }, trigger: Trigger) {
            const resposta = await http.smartCity.post('/triggers', trigger)
            commit(SAVE_TRIGGER, resposta.data['data']);
        },
        async [UPDATE_TRIGGER]({ commit }, trigger: Trigger) {
            const resposta = await http.smartCity.put('/triggers', trigger);
            commit(CHANGE_TRIGGER, resposta.data['data']);
        }
    }
}