import Structure from "@/interfaces/Structure";
import { useStore } from "@/store"
import { computed } from "vue";

export const useStructures = () => {
    const store = useStore();
    const structureParents = (structure: Structure | undefined, parents: string[] = []) => {
        if(structure) {
            parents.push(structure.id);
            if(structure.parent != 'root') {
                const parent = store.state.structure.structures.find(o => o.id == structure.parent);
                structureParents(parent, parents);
            }
        }

        return parents;
    };

    const userRootStructure = computed(() => store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.structure || '');
    const userTenant = computed(() => store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.tenant || '');

    const sensorIsAccessible = (sensor: Structure, structure: string) => {
        const parents = structureParents(sensor);

        return parents.includes(structure);
    };

    const assignedUsers = (structureId: string) => {
        return store.state.user.users?.filter(user => user.role.find(role => role.name == 'SMART_CITY')?.data?.structure == structureId);
    };

    return {
        userRootStructure,
        structureParents,
        sensorIsAccessible,
        assignedUsers,
        userTenant
    }
}