
import { defineComponent } from 'vue';
import { faEquals, faNotEqual, faGreaterThanEqual, faLessThanEqual, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faEquals, faNotEqual, faGreaterThanEqual, faLessThanEqual, faGreaterThan, faLessThan);

export default defineComponent({
    name: 'ComparatorItem',
    props: {
        option: {
            type: String,
            required: true
        }
    }
});
