import http from "@/http"
import { Report } from "@/interfaces/Report";
import Structure from "@/interfaces/Structure";
import User from "@/interfaces/User"
import { useFunctions } from "./UseFunctions";

export const usePayment = (tenant?: Structure, user?: User) => {
    const { convertToLocalMonthYear } = useFunctions();
    const createPayment = async (report: Report): Promise<string> => {
        if(!tenant || !user) {
            return '';
        }

        const date = new Date();
        if(report.preferenceId && report.paymentValidity && report.paymentValidity > date.getTime()) {
            return report.paymentUrl!;
        }

        const username = user.name.split(' ');
        const resposta = await http.payment.post('/payment', {
            id: report.id,
            payer: {
                "name": username[0],
                "surname": username.length == 1 ? '' : username[username.length - 1],
                "email": user.email
            },
            items: [
                {
                    title: `[SmartCity] - ${tenant.name} ref: ${convertToLocalMonthYear(report.referenceDate)}`,
                    description: `Utilização de monitoramento SmartCity referente ao mês ${convertToLocalMonthYear(report.referenceDate)}`,
                    quantity: 1,
                    unit_price: 1//report.valueTotal
                }
            ]
        });

        const { id, url } = resposta.data['data'];
        date.setDate(date.getDate() + 3);
        const updateReport = await http.smartCity.put('/report', {
            id: report.id,
            preferenceId: id,
            paymentUrl: url,
            paymentValidity: date.getTime()
        });

        return url;
    }

    const verifyPayment = async (report: Report) => {
        const response = await http.payment.get(`/payment/${report.preferenceId}`);
        
        if(response.data.data.result.status == 'approved') {
            const updateReport = await http.smartCity.put('/report', {
                id: report.id,
                paid: true
            });
        }
    }

    return {
        createPayment,
        verifyPayment
    }
}