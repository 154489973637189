import http from "@/http";
import Sensor from "@/interfaces/Sensor";
import { State } from "@/store";
import { GET_SENSORS } from "@/store/action-types";
import { DEFINE_SENSORS, LOGOUT } from "@/store/mutation-types";
import { Module } from "vuex";

export interface SensorState {
    sensors: Sensor[];
}

export const sensor: Module<SensorState, State> = {
    mutations: {
        [DEFINE_SENSORS](state, data: { sensors: Sensor[], gateways: string[], isMaster: boolean }) {
            if(data.isMaster) {
                state.sensors = data.sensors;
            } else {
                if(data.gateways) {
                    state.sensors = data.sensors.filter(sensor => data.gateways.includes(sensor.gateway));
                } else {
                    state.sensors = [];
                }
            }
        },
        [LOGOUT](state) {
            state.sensors = [];
        }
    },
    actions: {
        async [GET_SENSORS]({ commit }, data: { gateways: string[], isMaster: boolean }) {
            const resposta = await http.smartCity.get('/sensors/all');
            commit(DEFINE_SENSORS, { sensors: resposta.data['data'], gateways: data.gateways, isMaster: data.isMaster });
        }
    }
}