import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38c39d6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  ref: "optionUser",
  class: "option-user"
}
const _hoisted_4 = { class: "checkbox" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "selectUser",
      class: "select-user",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDropDown && _ctx.showDropDown(...args)))
    }, [
      (_ctx.users?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Escolha..."))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selectedUsers), 1))
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersList, (user) => {
        return (_openBlock(), _createElementBlock("div", {
          key: user.id
        }, [
          _createElementVNode("label", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              value: user.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formUsers) = $event))
            }, null, 8, _hoisted_5), [
              [_vModelCheckbox, _ctx.formUsers]
            ]),
            _createElementVNode("span", null, _toDisplayString(user.username), 1)
          ])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: "button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectUsers(_ctx.formUsers)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-check" })
        ])
      ])
    ], 512)
  ], 64))
}