
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { GET_SENSORS } from '@/store/action-types';
import SensorIcon from '../SensorIcon.vue';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'SensorConnection',
    components: {
        SensorIcon
    },
    props: {
        sensor: {
            type: Object as PropType<Structure>,
            required: true
        }
    },

    setup(props) {
        const store = useStore();

        return {
            sensorName: computed(() => store.state.sensor.sensors.find(s => s.id == props.sensor.sensor)?.name),
            sensorType: computed(() => store.state.sensor.sensors.find(s => s.id == props.sensor.sensor)?.type)
        }
    }
});
