
import { Rule } from '@/interfaces/Trigger';
import { useStore } from '@/store';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import SensorIcon from '../SensorIcon.vue';
import ComparatorItem from '@/views/Dashboard/Trigger/Comparator/ComparatorItem.vue';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faTrash);

export default defineComponent({
    name: 'RuleCard',
    components: {
        SensorIcon,
        ComparatorItem
    },
    props: {
        rule: {
            type: Object as PropType<Rule>,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const operator = ref<HTMLDivElement | null>(null);

        const sensor = computed(() => store.state.structure.sensors.find(sensor => sensor.id == props.rule.sensorId))

        onMounted(() => {
            if(props.rule.operator == 'OR') {
                operator.value!.style.backgroundColor = '#0A665F';
            } else if (props.rule.operator == 'AND') {
                operator.value!.style.backgroundColor = '#01739F';
            } else {
                operator.value!.style.backgroundColor = 'white';
                operator.value!.style.border = 'none';
            }
        });

        return {
            sensorName: computed(() => sensor.value?.name),
            sensorType: computed(() => sensor.value?.sensorType || ''),
            operator
        }
    }
});
