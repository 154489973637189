
import { defineComponent, ref } from 'vue';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faUpload);

export default defineComponent({
    name: 'FileUpload',
    props: {
        selectedFile: {
            type: String,
            default: ''
        }
    },
    emits: ['onFileSelect'],
    setup(props, { emit }) {
        const fileName = ref<HTMLSpanElement | undefined>();
        const handleFile = (event: Event) => {
            const inputFile = event.target as HTMLInputElement;
            const image = inputFile.files![0];
            
            emit('onFileSelect', image);
        };

        return {
            handleFile,
            fileName
        }
    }
});
