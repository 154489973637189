
import { Report } from '@/interfaces/Report';
import Structure from '@/interfaces/Structure';
import { PropType, defineComponent, watch } from 'vue';
import { useFunctions } from '@/composables/UseFunctions';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { usePayment } from '@/composables/UsePayment';
import { tenant } from '@/store/modules/tenants';
import { useStore } from '@/store';
import { useTenants } from '@/composables/UseTenants';
import { useStructures } from '@/composables/UseStrucutures';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faCartShopping);

export default defineComponent({
    name: 'ReportCard',
    props: {
        report: {
            type: Object as PropType<Report>,
            required: true
        },
        tenant: {
            type: Object as PropType<Structure>,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const { isMaster } = usePermissions();
        const { userTenant } = useStructures();
        const { convertToLocalDate, convertToLocalMonthYear, numberToLocalCurrency } = useFunctions();
        const { createPayment } = usePayment(props.tenant, store.state.user.user);

        const pay = async (report: Report) => {
            const { getReports } = useTenants(userTenant.value);
            const url = await createPayment(report);
            window.open(url, '_blank');
            getReports();
        }

        watch(() => props.report,
            (value, old) => {
                console.log('Atualizando...');
                console.log('Report Card: ', value);
            }
        );

        return {
            convertToLocalDate,
            convertToLocalMonthYear,
            numberToLocalCurrency,
            pay,
            isMaster
        }
    }
});
