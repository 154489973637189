
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { LOGOUT } from '@/store/mutation-types';
import { useRouter } from 'vue-router';
library.add(faRightFromBracket);

export default defineComponent({
    name: 'TopBar',

    setup() {
        const store = useStore();
        const router = useRouter();

        const logout = () => {
            store.commit(LOGOUT);
            router.push('/login');

        };

        return {
            user: computed(() => store.state.user.user),
            logout
        }
    }
});
