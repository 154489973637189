import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8959390e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alarm-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_AlarmCard = _resolveComponent("AlarmCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitleBar, { title: "Alarmes" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alarms, (alarm) => {
        return (_openBlock(), _createBlock(_component_AlarmCard, {
          key: alarm.id,
          alarm: alarm
        }, null, 8, ["alarm"]))
      }), 128))
    ])
  ], 64))
}