
import { Alarm } from '@/interfaces/Alarm';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { library, Library } from '@fortawesome/fontawesome-svg-core';
library.add(faLocationDot);

export default defineComponent({
    props: {
        alarm: {
            type: Object as PropType<Alarm>,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    emits: ['onRemoveAlarm'],
    setup(props, { emit }) {
        const windowMessage = ref<HTMLDivElement | null>(null);

        const removeAlarm = (alarmId: string) => {
            emit('onRemoveAlarm', alarmId);
        }

        const alarmType = (type: string) => {
            if(!type) return;
            switch (type) {
                case 'warning':
                    return 'is-warning';
                case 'alarm':
                    return 'is-danger';
                default:
                    return 'is-info';
            }
        }

        const definePosition = (index: number) => {
            if(windowMessage.value) {
                const windowHeight = windowMessage.value.getBoundingClientRect().height;

                windowMessage.value.style.top = `${index * windowHeight}px`;
            }
        }

        onMounted(() => {
            definePosition(props.index);
        });
        
        watch(() => props.index,
            (value) => {
                definePosition(value);
            }
        )

        return {
            windowMessage,
            removeAlarm,
            alarmType
        }
    }
})
