import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05cfbc63"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.endpoints, (endpoint) => {
    return (_openBlock(), _createElementBlock("div", {
      key: endpoint.id,
      onClick: ($event: any) => (_ctx.selectEndpoint(endpoint))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tower-broadcast" })
      ]),
      _createTextVNode(_toDisplayString(endpoint.name), 1)
    ], 8, _hoisted_1))
  }), 128))
}