import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f689a38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type == 'temperatura')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "item",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectType && _ctx.selectType(...args)))
        }, [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-thermometer-half" })
          ]),
          _createTextVNode("Temperatura")
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'umidade')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectType && _ctx.selectType(...args)))
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-droplet" })
          ]),
          _createTextVNode("Umidade")
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'nivel')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "item",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectType && _ctx.selectType(...args)))
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-house-flood-water" })
          ]),
          _createTextVNode("Nível")
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'contato')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "item",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectType && _ctx.selectType(...args)))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-toggle-off" })
          ]),
          _createTextVNode("Contato")
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'vazamento')
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "item",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.selectType && _ctx.selectType(...args)))
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-faucet-drip" })
          ]),
          _createTextVNode("Vazamento")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}