
import { useStore } from '@/store';
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import SensorItem from './SensorItem.vue';
import Structure from '@/interfaces/Structure';

export default defineComponent({
    name: 'SelectSensor',
    emits: ['onSelectSensor'],
    props: {
        selectedSensor: {
            type: Object as PropType<Structure | null>
        }
    },
    components: {
        SensorItem
    },
    setup(props, { emit }) {
        const store = useStore();
        const optionSensors = ref<HTMLDivElement | null>(null);
        const selectSensor = ref<HTMLDivElement | null>(null);

        const showDropDown = () => {
            if(!optionSensors.value || !selectSensor.value) {
                return;
            }

            const x = selectSensor.value.offsetLeft;
            const y = selectSensor.value.offsetTop + selectSensor.value.getBoundingClientRect().height;
            const width = selectSensor.value.getBoundingClientRect().width;

            optionSensors.value.style.left = `${x}px`;
            optionSensors.value.style.top = `${y}px`;
            optionSensors.value.style.width = `${width}px`;

            showOptions();
        };

        const showOptions = () => {
            if(optionSensors.value) {
                optionSensors.value.style.display = 'block';
            }
        };

        const closeOptions = () => {
            if(optionSensors.value) {
                optionSensors.value.style.display = 'none';
            }
        };

        const select = (sensor: Structure) => {
            closeOptions();
            emit('onSelectSensor', sensor);
        };

        const windowClick = (event: MouseEvent) => {
            if(!selectSensor.value?.contains(event.target as Node)) {
                closeOptions();
            }
        };

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        return {
            optionSensors,
            selectSensor,
            select,
            showDropDown,
            sensors: computed(() => store.state.structure.sensors)
        }
    }
});
