import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActiveIcon = _resolveComponent("ActiveIcon")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.user.name), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.user.username), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_ActiveIcon, {
          active: _ctx.user.active
        }, null, 8, ["active"])
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.user.permission), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.createdAt), 1)
    ]),
    _: 1
  }))
}