import http from "@/http";
import { Billing } from "@/interfaces/Billing";
import { State } from "@/store";
import { CREATE_BILLING, DELETE_BILLING, GET_BILLING } from "@/store/action-types";
import { DEACTIVE_BILLING, DEFINE_BILLING } from "@/store/mutation-types";
import { Module } from "vuex";

export interface BillingState {
    billings: Billing[]
}

export const billing: Module<BillingState, State> = {
    mutations: {
        [DEFINE_BILLING](state, billings: Billing[]) {
            state.billings = billings;
        },
        [DEACTIVE_BILLING](state, sensorId: string) {
            console.log('Before: ', state.billings);
            state.billings = state.billings.map(billing => {
                if(billing.sensor.id == sensorId && !billing.deleted) {
                    billing.deleted = true;
                }
                return billing;
            });
            console.log('After: ', state.billings);
        }
    },
    actions: {
        async [CREATE_BILLING]({ dispatch }, billing: { tenant: string, sensor: { id: string, name: string} }) {
            const resposta = await http.smartCity.post('/billing', billing);
        },
        async [GET_BILLING]({ commit }, tenant: string) {
            const resposta = await http.smartCity.get(`/billing/tenant/${tenant}`);
            commit(DEFINE_BILLING, resposta.data['data']);
        },
        async [DELETE_BILLING]({ commit }, sensorId: string) {
            const resposta = await http.smartCity.delete(`/billing/sensor/${sensorId}`);
            console.log('Response: ', resposta.data);
        }
    }
}