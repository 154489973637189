
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import ComparatorItem from './ComparatorItem.vue';

export default defineComponent({
    name: 'SelectComparator',
    emits: ['onSelectOption'],
    props: {
        selectedOption: {
            type: String,
        }
    },
    components: {
        ComparatorItem
    },
    setup(props, { emit }) {
        const selectComparator = ref<HTMLDivElement | null>(null);
        const optionComparator = ref<HTMLDivElement | null>(null);

        const showDropDown = () => {
            if(!selectComparator.value || !optionComparator.value) {
                return;
            }

            const x = selectComparator.value.offsetLeft;
            const y = selectComparator.value.offsetTop + selectComparator.value.getBoundingClientRect().height;
            const width = selectComparator.value.getBoundingClientRect().width;

            optionComparator.value.style.left = `${x}px`;
            optionComparator.value.style.top = `${y}px`;
            optionComparator.value.style.width = `${width}px`;

            showOptions();
        };

        const showOptions = () => {
            if(optionComparator.value) {
                optionComparator.value.style.display = 'block';
            }
        };

        const closeOptions = () => {
            if(optionComparator.value) {
                optionComparator.value.style.display = 'none';
            }
        };

        const selectOption = (option: string) => {
            closeOptions();
            emit('onSelectOption', option);
        };

        const windowClick = (event: MouseEvent) => {
            if(!selectComparator.value?.contains(event.target as Node)) {
                closeOptions();
            }
        };

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        return {
            selectComparator,
            optionComparator,
            showDropDown,
            selectOption
        }
    }
});
