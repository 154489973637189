
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { DELETE_STRUCTURE, GET_SENSORS, GET_STRUCTURES } from '@/store/action-types';
import { CLEAR_STRUCTURES, ORDER_STRUCTURE_BY_DATE_ASC, ORDER_STRUCTURE_BY_DATE_DESC, ORDER_STRUCTURE_BY_NAME_ASC, ORDER_STRUCTURE_BY_NAME_DESC } from '@/store/mutation-types';
import { computed, defineComponent, watch, ref, Ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import StructureCard from '@/components/StructureCard.vue';
import { faCircleInfo, faEdit, faTrashCan, faCirclePlus, faLink, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import PageHeader from '@/components/PageHeader.vue';
import Modal from '@/components/Modal.vue';
import SensorIcon from '@/components/SensorIcon.vue';
import ActiveIcon from '@/components/misc/ActiveIcon.vue';
import Sensor from '@/interfaces/Sensor';
import SensorValue from '@/components/sensor/SensorValue.vue';
import SensorConnection from '@/components/sensor/SensorConnection.vue';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faCircleInfo, faEdit, faTrashCan, faCirclePlus, faLink, faNetworkWired);

export default defineComponent({
    name: 'Structures',
    emits: ['selectedStructure', 'editSensor', 'editStructure', 'showStructureDetails', 'assignStrucutre', 'defineGateways'],
    props: {
        id: {
            type: String,
            required: true
        },
    },
    components: {
        StructureCard,
        PageHeader,
        Modal,
        SensorIcon,
        ActiveIcon,
        SensorValue,
        SensorConnection
    },
    setup(props, { emit }) {
        const route = useRoute();
        const store = useStore();
        const router = useRouter();
        const nameAsc = ref(false);
        const dateAsc = ref(false);
        const menuVisible = ref(false);
        const contextmenu = ref<HTMLDivElement | null>(null);
        const contextmenuNew = ref<HTMLDivElement | null>(null);
        const structures = computed(() => store.state.structure.structures);
        const history = ref([] as Structure[]);
        const orderBy = ref('');
        const selectedStructure = ref<Structure | null>(null);
        const selectedSensor = ref<Structure | null>(null);
        const selectedSensorData = ref<Sensor | null>(null);
        const modalConnectToUser = ref(false);
        let showingMap = false;

        const { root, isMaster, isSmartAdmin, hasReadPermission, hasUpdatePermission, hasDeletePermission } = usePermissions();

        const assignItemVisible = computed(() => (isMaster.value && selectedStructure.value?.parent == 'root') || (!isMaster.value && selectedStructure.value?.parent != 'root'));

        if (props.id == 'root' && root.value != 'root') {
            router.push(`/dashboard/structure/${root.value}`);
        }

        store.commit(CLEAR_STRUCTURES);
        store.dispatch(GET_STRUCTURES, props.id);

        watch(() => route.params,
            (toParams, previousParams) => {
                if(!showingMap) {
                    orderBy.value = '';
                    nameAsc.value = false;
                    nameAsc.value = false;
                    if (toParams.id === 'root') {
                        history.value.length = 0;
                    }

                    store.dispatch(GET_STRUCTURES, toParams.id);
                }
            }
        );

        const editSensor = (sensor: Structure | null) => {
            emit('editSensor', sensor);
            closeModalSensor();
        };

        const editStructure = (structure: Structure | null) => {
            emit('editStructure', structure);
        };

        const showStructureDetails = (structure: Structure | null) => {
            emit('showStructureDetails', structure);
        }

        const closeModalSensor = () => {
            selectedSensor.value = null;
        };

        const goToRoute = (structure: Structure) => {
            showingMap = false;
            if (structure.type == 'sensor') {
                selectedSensor.value = structure;
                return;
            }

            history.value.push(structure);
            goTo(structure.id, structure);
        };

        const goToMap = (structure: Structure) => {
            showingMap = true;
            if(structure.file) {
                router.push(`/dashboard/map/${structure.id}`);
            }
        }

        const navRouteClick = (structure: Structure | undefined = undefined) => {
            if (structure) {
                const index = history.value.findIndex(v => v.id == structure.id);
                history.value = history.value.slice(0, index + 1);
                goTo(structure.id, structure);
            } else {
                history.value.length = 0;
                goTo(root.value);
            }
        };

        const goTo = (id: string, structure: Structure | undefined = undefined) => {
            emit('selectedStructure', id, structure);
            router.push(`/dashboard/structure/${id}`);
        };

        const getLocalDateTime = (isoDate: string) => {
            const date = new Date(Date.parse(isoDate));
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        };

        const orderByName = () => {
            dateAsc.value = false;
            orderBy.value = 'name';
            nameAsc.value = !nameAsc.value;
            if (nameAsc.value) {
                store.commit(ORDER_STRUCTURE_BY_NAME_ASC);
            } else {
                store.commit(ORDER_STRUCTURE_BY_NAME_DESC);
            }
        };

        const orderByDate = () => {
            nameAsc.value = false;
            orderBy.value = 'date';
            dateAsc.value = !dateAsc.value;
            if (dateAsc.value) {
                store.commit(ORDER_STRUCTURE_BY_DATE_ASC);
            } else {
                store.commit(ORDER_STRUCTURE_BY_DATE_DESC);
            }
        }

        const nameOrder = computed(() => {
            return {
                'fa-caret-down': orderBy.value === 'name' && nameAsc.value,
                'fa-caret-up': orderBy.value === 'name' && !nameAsc.value
            }
        });

        const dateOrder = computed(() => {
            return {
                'fa-caret-down': orderBy.value === 'date' && dateAsc.value,
                'fa-caret-up': orderBy.value === 'date' && !dateAsc.value
            }
        });

        const clickOutsideContextMenu = (event: MouseEvent) => {
            if (!contextmenu.value) {
                return;
            }

            if (!contextmenu.value?.contains(event.target as Node)) {
                hideContextMenu();
            }

            selectedStructure.value = null;

        }

        const openContextMenu = (event: MouseEvent, structure: Structure) => {
            if (!contextmenu.value || structure.type == 'sensor') {
                return;
            }

            selectedStructure.value = structure;

            const { clientX, clientY } = event;
            const { width, height } = contextmenu.value.getBoundingClientRect();

            const x = clientX + width > window.innerWidth ? clientX - width : clientX;
            const y = clientY + height > window.innerHeight ? clientY - height : clientY;

            contextmenu.value.style.left = `${x}px`;
            contextmenu.value.style.top = `${y}px`;
            showContextMenu();
        }

        const deleteStructure = () => {
            if (selectedStructure.value) {
                store.dispatch(DELETE_STRUCTURE, selectedStructure.value);
                hideContextMenu();
            }
        }

        const deleteSensor = (structure: Structure) => {
            store.dispatch(DELETE_STRUCTURE, structure);
        }

        const showContextMenu = () => {
            if (contextmenu.value) {
                contextmenu.value.style.display = 'block';
            }
        }

        const hideContextMenu = () => {
            if (contextmenu.value) {
                contextmenu.value.style.display = 'none';
            }
        }

        const disableContextMenu = (event: MouseEvent) => {
            event.preventDefault();
        }

        const showModalConnectToUser = (structure: Structure | null) => {
            if (structure) {
                emit('assignStrucutre', structure.id);
            }
        }

        const showModalGateways = (structure: Structure | null) => {
            if (structure) {
                emit('defineGateways', structure.id);
            }
        }

        onMounted(() => {
            document.addEventListener('click', clickOutsideContextMenu);
            document.addEventListener('contextmenu', disableContextMenu);
        });

        onUnmounted(() => {
            document.removeEventListener('click', clickOutsideContextMenu);
            document.removeEventListener('contextmenu', disableContextMenu);
        });

        return {
            structures,
            history,
            router,
            nameOrder,
            dateOrder,
            menuVisible,
            goToRoute,
            navRouteClick,
            getLocalDateTime,
            orderByName,
            orderByDate,
            openContextMenu,
            contextmenu,
            deleteStructure,
            selectedSensor,
            selectedSensorData,
            closeModalSensor,
            editSensor,
            editStructure,
            selectedStructure,
            showStructureDetails,
            modalConnectToUser,
            showModalConnectToUser,
            showModalGateways,
            assignItemVisible,
            isSmartAdmin,
            hasReadPermission,
            hasUpdatePermission,
            hasDeletePermission,
            goToMap,
            deleteSensor
        }
    }
});
