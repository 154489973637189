
import User from '@/interfaces/User';
import { computed, defineComponent, PropType } from 'vue'
import Card from './user/Card.vue';
import ActiveIcon from './misc/ActiveIcon.vue';

export default defineComponent({
    name: 'UserCard',
    props: {
        user: {
            type: Object as PropType<User>,
            required: true
        }
    },
    components: {
        Card,
        ActiveIcon
    },
    setup(props) {
        return {
            createdAt: computed(() => {
                let date = new Date(Date.parse(props.user.createdAt));
                return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
            }),
        }
    }
});
