
import { useStore } from '@/store';
import { GET_SENSORS, GET_USERS } from '@/store/action-types';
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { io } from 'socket.io-client';
import Notifications from '@/components/Notifications.vue';
import { ACK_ALARM, ADD_ALARM, DEFINE_ALARMS } from '@/store/mutation-types';
import { useStructures } from '@/composables/UseStrucutures';
import { useTenants } from '@/composables/UseTenants';
import { usePermissions } from '@/composables/UsePermissions';
import { usePayment } from '@/composables/UsePayment';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'Home',
    components: {
        Notifications
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        var audioStopTimeout: number;
        var audio = ref<HTMLAudioElement>();

        const { isAdmin, isMaster, root } = usePermissions();

        const updateSensor = setInterval(() => {
                store.dispatch(GET_SENSORS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });
        }, 5000);

        const savedAlarms = computed(() => store.state.alarm.alarms);
        const socket = io(process.env.VUE_APP_SOCKET_URI, {
            path: process.env.VUE_APP_SOCKET_PATH,
            auth: {
                token: store.state.user.token.access_token
            }
        });
        // const socket = io('http://localhost:3000',{
        //     auth: {
        //         token: store.state.user.token.access_token
        //     }
        // });

        socket.on('alarm', (message) => {
            const path = message.path;
            if(path.includes(root.value)) {
                store.commit(ADD_ALARM, message);
                playAlarmSiren();
            }
        });

        socket.on('connect', () => {
            console.log('Conectado ao servidor');
        });

        socket.on('disconnect', () => {
            console.log('Desconectado do servidor');
        });

        const ackAlarm = (alarmId: string) => {
            store.commit(ACK_ALARM, alarmId);
        };

        const playAlarmSiren = () => {
            if(audioStopTimeout) {
                clearTimeout(audioStopTimeout);
            }

            if(audio.value) {
                audioStopTimeout = setTimeout(() => {
                    audio.value?.pause();
                }, 10000);
                
                audio.value.currentTime = 0;
                audio.value.play();
            }
        }

        onMounted(async () => {
            if(isAdmin.value) { 
                store.dispatch(GET_USERS, store.state.user.token.access_token);
            }

            if(!isMaster.value) {
                const { userTenant } = useStructures();
                const { reports, getReports } = useTenants(userTenant.value);
                await getReports();
                const report = reports.value?.find(r => r.paid == false && r.preferenceId);
                const { verifyPayment } = usePayment();
                if(report) {
                    await verifyPayment(report);
                }
            }
        });

        onUnmounted(() => {
            clearInterval(updateSensor);
            socket.disconnect();
        });

        return {
            audio,
            ackAlarm,
            savedAlarms,
            activeAlarms: computed(() => savedAlarms.value?.filter(alarm => !alarm?.ack))
        }
    }
});
