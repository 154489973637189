
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import UserCard from '@/components/UserCard.vue';
import { useStore } from '@/store';
import { GET_USERS, DELETE_USER, UPDATE_ROLE } from '@/store/action-types';
import Modal from '@/components/Modal.vue';
import User from '@/interfaces/User';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import UserRole from '@/interfaces/UserRole';
library.add(faUser);

export default defineComponent({
    name: 'Users',
    components: {
        TitleBar,
        PageHeader,
        UserCard,
        Modal
    },
    setup() {
        const store = useStore();
        store.dispatch(GET_USERS, store.state.user.token.access_token);
        const modalActive = ref(false);
        const selectedUser = ref<User | null>(null);

        const role = ref('');
        const permissions = ref<string[]>([]);

        const showModal = (user: User) => {
            if(user.role?.filter(r => r.data?.role == 'MASTER').length < 1) {
                selectedUser.value = user;
                modalActive.value = true;
            }
        }

        const hideModal = () => {
            selectedUser.value = null;
            modalActive.value = false;
        }

        const deleteUser = (id: string) => {
            store.dispatch(DELETE_USER, id);
            hideModal();
        }

        const setTotalControl = () => {
            if (permissions.value.includes('all')) {
                permissions.value = [];
                return;
            }
            permissions.value = ['all'];
        }

        const verifyPermissions = () => {
            if (permissions.value.includes('read') &&
                permissions.value.includes('create') &&
                permissions.value.includes('update') &&
                permissions.value.includes('delete')
            ) {
                setTotalControl();
            }
        }

        const saveUser = (user: User, role: string, permissions: string[]) => {

            const userRoles = user.role.map(r => {
                if(r.name == 'SMART_CITY') {
                    const smartUser = {
                        ...r,
                        data: {
                            ...r.data,
                            role,
                            permissions
                        }
                    }

                    return smartUser;
                } else {
                    return r;
                }
            });

            user.role = userRoles;

            store.dispatch(UPDATE_ROLE, { id: user.id, data: userRoles.find(role => role.name == 'SMART_CITY')?.data });
        }

        watch(() =>
            selectedUser.value,
            (value, old) => {
                const smartUser = value?.role.find(role => role.name == 'SMART_CITY');
                role.value = smartUser?.data.role;
                permissions.value = smartUser?.data.permissions || [];
            }
        )

        return {
            users: computed(() => store.state.user.users.filter(user => user.id != store.state.user.user.id)),
            modalActive,
            selectedUser,
            showModal,
            hideModal,
            deleteUser,
            role,
            permissions,
            setTotalControl,
            verifyPermissions,
            saveUser
        }
    }
});
