import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb8c81a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  ref: "type",
  class: "type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TriggerType = _resolveComponent("TriggerType")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "selectType",
      class: "trigger-type",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDropDownType && _ctx.showDropDownType(...args)))
    }, [
      (!_ctx.selectedType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Escolha..."))
        : (_openBlock(), _createBlock(_component_TriggerType, {
            key: 1,
            type: _ctx.selectedType
          }, null, 8, ["type"]))
    ], 512),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TriggerType, {
        type: "alarm",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.select('alarm')))
      }),
      _createVNode(_component_TriggerType, {
        type: "warning",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select('warning')))
      })
    ], 512)
  ], 64))
}