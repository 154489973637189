
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'SensorValue',
    props: {
        sensor: {
            type: Object as PropType<Structure>,
            required: true
        },
        size: {
            type: String,
        }
    },
    setup(props) {
        const store = useStore();

        const sensorValue = computed(() => {
            const value =  store.state.sensor.sensors?.find(s => s.id == props.sensor.sensor)?.value || store.state.map.endpointSensors?.find(s => s.id == props.sensor.sensor)?.value;

            if(typeof value == 'number') {
                if(props.sensor.normalization) {
                    const normValue = (value - props.sensor.normalization.inputMin) / (props.sensor.normalization.inputMax - props.sensor.normalization.inputMin);
                    const returnValue = (((props.sensor.normalization.outputMax - props.sensor.normalization.outputMin) * normValue) + props.sensor.normalization.outputMin);
                    return Number(returnValue).toFixed(1);
                }

                return value.toFixed(1);
            }

            if(props.sensor.sensorType == 'contato'  && typeof value == 'boolean') {
                if(value) {
                    return props.sensor.mapped?.true;
                } else {
                    return props.sensor.mapped?.false;
                }
            }

            return value;
        });

        const sensorType = (): string => {
            switch(props.sensor.sensorType) {
                case "temperatura":
                    return '°C';
                case "umidade":
                    return '%RH';
                case "nivel":
                    return 'm³';
            }

            return '';
        };

        return {
            sensorValue,
            sensorType
        }
    }
});
