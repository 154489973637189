import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e3a30e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "sensor" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "comparator" }
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorIcon = _resolveComponent("SensorIcon")!
  const _component_ComparatorItem = _resolveComponent("ComparatorItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_SensorIcon, { type: _ctx.sensorType }, null, 8, ["type"])
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.sensorName), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ComparatorItem, {
        option: _ctx.rule.comparator
      }, null, 8, ["option"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.rule.value), 1)
    ]),
    _createElementVNode("div", {
      ref: "operator",
      class: "operator"
    }, _toDisplayString(_ctx.rule.operator), 513)
  ]))
}