
import Structure from '@/interfaces/Structure';
import { defineComponent, PropType } from 'vue';
import SensorIcon from '@/components/SensorIcon.vue';

export default defineComponent({
    name: 'SensorItem',
    components: {
        SensorIcon
    },
    props: {
        sensor: {
            type: Object as PropType<Structure>,
            required: true
        }
    }
});
