
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'Modal',
    emits: ['closeModalClick'],
    props: {
        isActive: {
            type: Boolean,
        },
        title: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: 'normal'
        }
    },

    setup(props, { emit } ) {

        const closeModal = () => {
            emit('closeModalClick');
        }

        return {
            closeModal,
        }
    }
})
