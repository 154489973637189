import axios, { AxiosInstance } from 'axios';

const http = {
    client: axios.create({
        baseURL: "http://localhost:3000"
    }),
    auth: axios.create({
        baseURL: process.env.VUE_APP_AUTH_URI
    }),
    smartCity: axios.create({
        baseURL: process.env.VUE_APP_SERVER_URI
    }),
    payment: axios.create({
        baseURL: process.env.VUE_APP_PAYMENT_URI
    }),
    message: axios.create({
        baseURL: "http://localhost:9000"
    })
}

export default http