import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "monitor-body" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = { class: "line-option" }
const _hoisted_4 = { class: "option" }
const _hoisted_5 = {
  key: 0,
  class: "location-select"
}
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "line-option" }
const _hoisted_9 = { class: "option" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "line-option" }
const _hoisted_12 = { class: "option" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "button-group" }
const _hoisted_15 = {
  ref: "locationOptions",
  class: "options-menu"
}
const _hoisted_16 = {
  ref: "endpointOptions",
  class: "options-menu"
}
const _hoisted_17 = {
  ref: "sensorTypeOptions",
  class: "options-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_MonitorCard = _resolveComponent("MonitorCard")!
  const _component_EndpointOption = _resolveComponent("EndpointOption")!
  const _component_SensorType = _resolveComponent("SensorType")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_StructureList = _resolveComponent("StructureList")!
  const _component_EndpointSelect = _resolveComponent("EndpointSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitleBar, { title: "Monitoramento" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader, { class: "monitor-options" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "filter-option",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showFilterModal && _ctx.showFilterModal(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-filter" })
            ]),
            _createElementVNode("div", {
              class: "display-option",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeDisplayMode && _ctx.changeDisplayMode(...args)))
            }, [
              (_ctx.isListMode)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "fa-solid fa-table-cells-large"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "fa-solid fa-list"
                  }))
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["monitor-content", { list: _ctx.isListMode }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sensors, (sensor) => {
          return (_openBlock(), _createBlock(_component_MonitorCard, {
            key: sensor.id,
            structure: sensor,
            isListMode: _ctx.isListMode
          }, null, 8, ["structure", "isListMode"]))
        }), 128))
      ], 2)
    ]),
    _createVNode(_component_Modal, {
      size: "large",
      ref: "modal",
      isActive: _ctx.isFilterModalOn,
      title: "Filtrar Sensores",
      onCloseModalClick: _ctx.hideFilterModal,
      onClick: _ctx.modalFilterClick
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-filter" })
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: "icon is-check",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleFilterLocation && _ctx.toggleFilterLocation(...args)))
            }, [
              (_ctx.isFilterLocation)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "fa-solid fa-square-xmark"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "fa-regular fa-square"
                  }))
            ]),
            _createTextVNode(" Localização ")
          ]),
          _createElementVNode("div", {
            ref: "locationSelect",
            class: _normalizeClass(["option-select", { disabled: !_ctx.isFilterLocation }]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openSelect(_ctx.locationSelect, _ctx.locationOptions, _ctx.isFilterLocation)))
          }, [
            (_ctx.selectedStructure)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-folder" })
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.selectedStructure.name), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, "Escolha a Localização"))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", {
              class: "icon is-check",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleFilterEndpoint && _ctx.toggleFilterEndpoint(...args)))
            }, [
              (_ctx.isFilterEndpoint)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "fa-solid fa-square-xmark"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "fa-regular fa-square"
                  }))
            ]),
            _createTextVNode(" Endpoint ")
          ]),
          _createElementVNode("div", {
            ref: "endpointSelect",
            class: _normalizeClass(["option-select", { disabled: !_ctx.isFilterEndpoint}]),
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openSelect(_ctx.endpointSelect, _ctx.endpointOptions, _ctx.isFilterEndpoint)))
          }, [
            (_ctx.selectedEndpoint)
              ? (_openBlock(), _createBlock(_component_EndpointOption, {
                  key: 0,
                  endpoint: _ctx.selectedEndpoint
                }, null, 8, ["endpoint"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, "Escolha o Endpoint"))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", {
              class: "icon is-check",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleFilterType && _ctx.toggleFilterType(...args)))
            }, [
              (_ctx.isFilterType)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "fa-solid fa-square-xmark"
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: "fa-regular fa-square"
                  }))
            ]),
            _createTextVNode(" Tipo ")
          ]),
          _createElementVNode("div", {
            ref: "sensorTypeSelect",
            class: _normalizeClass(["option-select", { disabled: !_ctx.isFilterType }]),
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openSelect(_ctx.sensorTypeSelect, _ctx.sensorTypeOptions, _ctx.isFilterType)))
          }, [
            (_ctx.selectedSensorType)
              ? (_openBlock(), _createBlock(_component_SensorType, {
                  key: 0,
                  type: _ctx.selectedSensorType
                }, null, 8, ["type"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_13, "Escolha o Tipo de Sensor"))
          ], 2)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", {
            class: "button",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.hideFilterModal && _ctx.hideFilterModal(...args)))
          }, "Cancelar"),
          _createElementVNode("button", {
            class: "button save-button",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.applyFilter && _ctx.applyFilter(...args)))
          }, "Aplicar")
        ])
      ]),
      _: 1
    }, 8, ["isActive", "onCloseModalClick", "onClick"]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_StructureList, { onOnItemSelected: _ctx.selectStructure }, null, 8, ["onOnItemSelected"])
    ], 512),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_EndpointSelect, { onOnSelectEndpoint: _ctx.selectEndpoint }, null, 8, ["onOnSelectEndpoint"])
    ], 512),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_SensorType, {
        type: "temperatura",
        onOnTypeSelect: _ctx.selectSensorType
      }, null, 8, ["onOnTypeSelect"]),
      _createVNode(_component_SensorType, {
        type: "umidade",
        onOnTypeSelect: _ctx.selectSensorType
      }, null, 8, ["onOnTypeSelect"]),
      _createVNode(_component_SensorType, {
        type: "nivel",
        onOnTypeSelect: _ctx.selectSensorType
      }, null, 8, ["onOnTypeSelect"]),
      _createVNode(_component_SensorType, {
        type: "contato",
        onOnTypeSelect: _ctx.selectSensorType
      }, null, 8, ["onOnTypeSelect"]),
      _createVNode(_component_SensorType, {
        type: "vazamento",
        onOnTypeSelect: _ctx.selectSensorType
      }, null, 8, ["onOnTypeSelect"])
    ], 512)
  ], 64))
}