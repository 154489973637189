import http from "@/http";
import Structure from "@/interfaces/Structure";
import { State } from "@/store";
import { ADD_STRUCTURE, CREATE_BILLING, DELETE_BILLING, DELETE_STRUCTURE, GET_PARENTS, GET_STRUCTURES, GET_USER_SENSORS, GET_USER_TENANT, UPDATE_STRUCTURE, UPLOAD_FILE } from "@/store/action-types";
import { CHANGE_STRUCTURE, CLEAR_STRUCTURES, DEFINE_GATEWAYS, DEFINE_STRUCTURES, DEFINE_USER_SENSORS, LOGOUT, ORDER_STRUCTURE_BY_DATE_ASC, ORDER_STRUCTURE_BY_DATE_DESC, ORDER_STRUCTURE_BY_NAME_ASC, ORDER_STRUCTURE_BY_NAME_DESC, SAVE_STRUCTURE } from "@/store/mutation-types";
import { Module } from "vuex";

export interface StructureState {
    structures: Structure[],
    sensors: Structure[],
    gateways: string[]
}

export const structure: Module<StructureState, State> = {
    mutations: {
        [DEFINE_STRUCTURES](state, structures: Structure[]) {
            state.structures = structures;
        },
        [DEFINE_USER_SENSORS](state, structures: Structure[]) {
            state.sensors = structures;
        },
        [SAVE_STRUCTURE](state, structure: Structure) {
            state.structures.push(structure);
        },
        [CHANGE_STRUCTURE](state, structure: Structure) {
            const index = state.structures.findIndex(s => s.id == structure.id);
            state.structures[index] = structure;
        },
        [ORDER_STRUCTURE_BY_NAME_ASC](state) {
            state.structures.sort((a, b) => a.name.localeCompare(b.name));
        },
        [ORDER_STRUCTURE_BY_NAME_DESC](state) {
            state.structures.sort((a, b) => b.name.localeCompare(a.name));
        },
        [ORDER_STRUCTURE_BY_DATE_ASC](state) {
            state.structures.sort((a, b) => {
                const aDate = new Date(Date.parse(a.createdAt));
                const bDate = new Date(Date.parse(b.createdAt));
                if (aDate < bDate) return -1;
                if (aDate > bDate) return 1;
                return 0;
            });
        },
        [ORDER_STRUCTURE_BY_DATE_DESC](state) {
            state.structures.sort((a, b) => {
                const aDate = new Date(Date.parse(a.createdAt));
                const bDate = new Date(Date.parse(b.createdAt));
                if (aDate < bDate) return 1;
                if (aDate > bDate) return -1;
                return 0;
            });
        },
        [CLEAR_STRUCTURES](state) {
            state.structures = [];
        },
        [DEFINE_GATEWAYS](state, structure: Structure) {
            state.gateways = structure.gateways || [];
        },
        [LOGOUT](state) {
            state.structures = [];
            state.sensors = [];
            state.gateways = [];
        }
    },
    actions: {
        async [GET_STRUCTURES]({ commit }, parent: string) {
            let searchFor = '/all';
            if (parent) {
                searchFor = `/parent/${parent}`;
            }
            const resposta = await http.smartCity.get(`/structures${searchFor}`)
            commit(DEFINE_STRUCTURES, resposta.data['data'])
        },
        async [GET_USER_TENANT]({ commit }, id: string ) {
            if(id) {
                const resposta = await http.smartCity.get(`/structures/${id}`);
                commit(DEFINE_GATEWAYS, resposta.data['data']);
            }
        },
        async [GET_USER_SENSORS]({ commit }) {
            const resposta = await http.smartCity.get('/structures/sensors/all')
            commit(DEFINE_USER_SENSORS, resposta.data['data']);
            return resposta.data['data'];
        },
        async [ADD_STRUCTURE]({ commit, dispatch }, data: { structure: Structure, tenant: string }) {
            const resposta = await http.smartCity.post('/structures', data.structure);
            const structure: Structure = resposta.data['data'];
            if(structure.type == 'sensor') {
                let tenant = '';
                if(structure.parent == 'root') {
                    tenant = structure.id;
                } else {
                    const parents: Structure[] = await dispatch(GET_PARENTS, structure.id);
                    tenant = parents.find(st => st.parent == 'root')!.id;
                }

                await dispatch(CREATE_BILLING, { sensor: { id: structure.id, name: structure.name }, tenant: tenant });
            }
            commit(SAVE_STRUCTURE, structure);
        },
        async [UPDATE_STRUCTURE]({ commit }, structure: Structure) {
            structure.modifiedAt = new Date().toISOString();
            const resposta = await http.smartCity.put('/structures', structure)
            commit(CHANGE_STRUCTURE, structure);
        },
        async [DELETE_STRUCTURE]({ dispatch }, structure: Structure) {
            const resposta = await http.smartCity.delete(`/structures/${structure.id}`)
            if(structure.type == 'sensor' && resposta.data['data']) {
                await dispatch(DELETE_BILLING, structure.id);
            }
            dispatch(GET_STRUCTURES, structure.parent);
        },
        async [UPLOAD_FILE]({ commit }, file: File) {
            const formData = new FormData();
            formData.append('image', file);
            const resposta = await http.smartCity.post('/image/upload', formData);
            return resposta.data['data'];
        },
        async [GET_PARENTS](_, id: string) {
            const resposta = await http.smartCity(`/structures/parents/${id}`);
            return resposta.data['data'];

        }
    }
}