export const DEFINE_USERS = 'DEFINE_USERS';
export const REMOVE_USER = 'REMOVE_USER';
export const DEFINE_TENANTS = 'DEFINE_TENANTS';
export const DEFINE_STRUCTURES = 'DEFINE_STRUCTURES';
export const CLEAR_STRUCTURES = 'CLEAR_STRUCTURES';
export const DEFINE_USER_SENSORS = 'DEFINE_USER_SENSORS';
export const DEFINE_PERMISSIONS = 'DEFINE_PERMISSIONS';
export const SAVE_STRUCTURE = 'SAVE_STRUCTURE';
export const CHANGE_STRUCTURE = 'CHANGE_STRUCTURE';
export const ORDER_STRUCTURE_BY_NAME_ASC = 'ORDER_STRUCTURE_BY_NAME_ASC';
export const ORDER_STRUCTURE_BY_NAME_DESC = 'ORDER_STRUCTURE_BY_NAME_DESC';
export const ORDER_STRUCTURE_BY_DATE_ASC = 'ORDER_STRUCTURE_BY_DATE_ASC';
export const ORDER_STRUCTURE_BY_DATE_DESC = 'ORDER_STRUCTURE_BY_DATE_DESC';
export const DEFINE_ENDPOINTS = 'DEFINE_ENDPOINTS';
export const DEFINE_SENSORS = 'DEFINE_SENSORS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const DEFINE_TRIGGERS = 'DEFINE_TRIGGERS';
export const SAVE_TRIGGER = 'SAVE_TRIGGER';
export const CHANGE_TRIGGER = 'CHANGE_TRIGGER';
export const DEFINE_ALARMS = 'DEFINE_ALARMS';
export const ADD_ALARM = 'ADD_ALARM';
export const ACK_ALARM = 'ACK_ALARM';
export const SET_ROLE = 'SET_ROLE';
export const DEFINE_GATEWAYS = 'DEFINE_GATEWAYS';
export const SET_GATEWAYS = 'SET_GATEWAYS';
export const DEFINE_VIEW_MAP = 'DEFINE_VIEW_MAP';
export const DEFINE_VIEW_MAP_STRUCTURE = 'DEFINE_VIEW_MAP_STRUCTURE';
export const DEFINE_VIEW_MAP_SENSORS = 'DEFINE_VIEW_MAP_SENSORS';
export const DEFINE_VIEW_MAP_ENDPOINT_SENSORS = 'DEFINE_VIEW_MAP_ENDPOINT_SENSORS';
export const DEFINE_HISTORY_ALARMS = 'DEFINE_HISTORY_ALARMS';
export const DEFINE_BILLING = 'DEFINE_BILLING';
export const DEACTIVE_BILLING = 'DEACTIVE_BILLING';