import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74afc3cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "structure" }
const _hoisted_2 = { class: "item-radio" }
const _hoisted_3 = { class: "item-more" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "item-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parents, (parent) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["item-structure", { 'visible': _ctx.isVisible}]),
        key: parent.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            (_ctx.hasChildren(parent.id))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.childrenIsVisible)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        "font-awesome-icon": "",
                        onClick: _ctx.toggleChildren,
                        icon: "fa-regular fa-square-minus"
                      }, null, 8, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        onClick: _ctx.toggleChildren,
                        icon: "fa-regular fa-square-plus"
                      }, null, 8, ["onClick"]))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.selectItem(parent)),
            class: _normalizeClass(["item-name", { selected: parent.name == _ctx.itemSelected}])
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-folder" })
            ]),
            _createTextVNode(_toDisplayString(parent.name), 1)
          ], 10, _hoisted_5)
        ]),
        (_openBlock(), _createBlock(_resolveDynamicComponent('StructureItem'), {
          structures: _ctx.list,
          parent: parent.id,
          isVisible: _ctx.childrenIsVisible,
          onOnItemSelected: _ctx.selectItem
        }, null, 40, ["structures", "parent", "isVisible", "onOnItemSelected"]))
      ], 2))
    }), 128))
  ]))
}