import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e945c67c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-body" }
const _hoisted_2 = { class: "map-options" }
const _hoisted_3 = { class: "share" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "item-icon" }
const _hoisted_6 = { class: "item-name" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_SensorMap = _resolveComponent("SensorMap")!
  const _component_SensorIcon = _resolveComponent("SensorIcon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TitleBar, {
      title: `${_ctx.structure.name} - (Mapa da Estrutura)`
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageHeader, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-share-nodes",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareLink(_ctx.structure.id)))
              })
            ]),
            _createElementVNode("div", {
              class: "sensors-configuration",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showSettings && _ctx.showSettings(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-sliders" })
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        ref: "containerElement",
        class: "map",
        style: _normalizeStyle({ backgroundImage: `url(${_ctx.image})`})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showSensors, (sensor) => {
          return (_openBlock(), _createBlock(_component_SensorMap, {
            key: sensor.id,
            structure: sensor,
            style: _normalizeStyle({ left: `${sensor.position!.left * _ctx.containerWidth}px`, top: `${sensor.position!.top * _ctx.containerHeight}px`}),
            onMousedown: ($event: any) => (_ctx.handleMouseDown($event, sensor.id))
          }, null, 8, ["structure", "style", "onMousedown"]))
        }), 128))
      ], 4)
    ]),
    _createVNode(_component_Modal, {
      title: "Sensores",
      isActive: _ctx.settings,
      onCloseModalClick: _ctx.hideSettings
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode("Escolha os Sensores Visíveis no Mapa: "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sensors, (sensor) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sensor.id,
              class: "sensor-item"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_SensorIcon, {
                  type: sensor.sensorType || ''
                }, null, 8, ["type"])
              ]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(sensor.name), 1),
              _createElementVNode("div", {
                class: "item-show",
                onClick: ($event: any) => (_ctx.toggleDisplay(sensor))
              }, [
                (sensor.position?.show)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: "fa-solid fa-toggle-on"
                    }))
                  : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: "fa-solid fa-toggle-off"
                    }))
              ], 8, _hoisted_7)
            ]))
          }), 128))
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "button save-button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideSettings && _ctx.hideSettings(...args)))
          }, "Ok")
        ])
      ]),
      _: 1
    }, 8, ["isActive", "onCloseModalClick"])
  ], 64))
}