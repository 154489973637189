
import { PropType, defineComponent, onMounted, watch } from 'vue';
import Modal from '../Modal.vue';
import Structure from '@/interfaces/Structure';
import { ref } from '@vue/reactivity';
import { useStore } from '@/store';
import CheckBox from '../misc/CheckBox.vue';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { computed } from '@vue/reactivity';
import { useStructures } from '@/composables/UseStrucutures';
library.add(faLink);

export default defineComponent({
    name: 'ModalSetStructure',
    emits: ['onCloseClick', 'onSaveClick'],
    props: {
        structure: {
            type: String,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal,
        CheckBox
    },
    setup(props, { emit }) {
        const store = useStore();
        const title = ref('');
        const selectedStructure = ref<Structure>({} as Structure);
        const selectedUsers = ref<string[]>([]);
        const removeAssignment = ref<string[]>([]);

        const { assignedUsers } = useStructures();

        const close = () => {
            emit('onCloseClick');
        }

        const selectUser = (userId: string) => {
            if(selectedUsers.value.includes(userId)) {
                selectedUsers.value = selectedUsers.value?.filter(user => user != userId);
            } else {
                selectedUsers.value.push(userId);
            }
        };

        const saveSettings = () => {
            close();
            emit('onSaveClick', { structure: props.structure, users: selectedUsers.value });
        };

        watch(() => 
            props.structure,
            (value, old) => {
                selectedStructure.value = store.state.structure.structures.find(structure => structure.id == value) || {} as Structure;
                title.value = selectedStructure.value.name;
                selectedUsers.value = assignedUsers(value).map(user => user.id);
            }
        )

        return {
            selectedStructure,
            selectedUsers,
            selectUser,
            title,
            close,
            saveSettings,
            users: computed(() => store.state.user.users?.filter(user => user.id != store.state.user.user.id && user.role.find(role => role.name == 'SMART_CITY')?.data.role != 'MASTER'))
        }
    }
});
