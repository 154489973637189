import http from "@/http";
import Permission from "@/interfaces/Permission";
import User from "@/interfaces/User";
import UserRole from "@/interfaces/UserRole";
import { State } from "@/store";
import { CREATE_USER, DELETE_USER, GET_USERS, GET_USERS_PERMISSION, GET_USER_DATA, GET_USER_PAYLOAD, GET_USER_ROLE, UPDATE_ROLE, USER_AUTHENTICATE } from "@/store/action-types";
import { DEFINE_PERMISSIONS, DEFINE_USERS, LOGOUT, REMOVE_USER, SET_ROLE, SET_TOKEN, SET_USER } from "@/store/mutation-types";
import { Module } from "vuex";

export interface Token {
    access_token: string;
    refresh_token: string;
}

export interface UserState {
    user: User;
    users: User[];
    token: Token;
    userRole: UserRole[];
}

export const user: Module<UserState, State> = {
    mutations: {
        [DEFINE_USERS](state, users: User[]) {
            state.users = users;
        },
        [REMOVE_USER](state, id: string) {
            state.users = state.users.filter(user => user.id != id);
        },
        [DEFINE_PERMISSIONS](state, permissions: Permission[]) {
            state.users = state.users.map(user => {
                return {
                    ...user,
                    permission: permissions.find(permission => permission.user == user.id)?.permission
                }
            });
        },
        [SET_TOKEN](state, token: Token) {
            state.token = token;
        },
        [SET_USER](state, user: User) {
            state.user = user;
        },
        [SET_ROLE](state, userRole: UserRole[]) {
            state.userRole = userRole;
            console.log('User Role: ', state.userRole);
        },
        [LOGOUT](state) {
            state.user = {} as User;
            state.userRole = [];
            state.users = [];
            state.token = {} as Token;
        }
    },
    actions: {
        async [CREATE_USER]({ dispatch, state }, user: any) {
            const resposta = await  http.auth.post('/user', user, {
                headers: {
                    Authorization: `Bearer ${state.token.access_token}`
                }
            });

            await dispatch(GET_USERS, state.token.access_token);
        },
        async [DELETE_USER]({ commit, state }, id: string) {
            const resposta = await http.auth.delete(`/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${state.token.access_token}`
                }
            });

            if(resposta.status == 204) {
                commit(REMOVE_USER, id);
            }
        },
        async [GET_USERS]({ commit }, token: string) {
            console.log('Iniciando chamada da api...');
            const resposta = await http.auth.get('/user', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
                
            console.log('Usuários carregados...');
            const users: User[] = resposta.data['data']['data'];

            const smartUsers = users.filter(user => user.role?.filter(role => role.name == 'SMART_CITY').length > 0);

            commit(DEFINE_USERS, smartUsers);
        },
        async [USER_AUTHENTICATE]({ commit, dispatch }, user: User) {
            const resposta = await http.auth.get('/auth/login', {
                auth: user,
                validateStatus: (status: number) => status >= 200 && status < 405
                
            });
            if(resposta.status == 200) {
                commit(SET_TOKEN, resposta.data['data']);
                await dispatch(GET_USER_PAYLOAD, resposta.data['data']['access_token']);
            }
        },
        async [GET_USER_PAYLOAD]({ commit, dispatch }, token: string) {
            const resposta = await http.auth.get('/auth/payload', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { id } = resposta.data['data']['payload'];
            await dispatch(GET_USER_DATA, { id, token } );
            // await http.auth.get(`/user/${id}`, {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // }).then(async (resposta_1) => {
            //     commit(SET_USER, resposta_1.data['data']);
            //     await http.auth.get(`/user/${id}/role`, {
            //         headers: {
            //             Authorization: `Bearer ${token}`
            //         }
            //     }).then(resposta_2 => commit(SET_ROLE, resposta_2.data['data']));
            // });
        },
        async [GET_USER_DATA]({ commit, dispatch }, params: { id: string, token: string }) {
            const { id, token } = params;
            const resposta = await http.auth.get(`/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            commit(SET_USER, resposta.data['data']);
            await dispatch(GET_USER_ROLE, { id, token })
        },
        async [GET_USER_ROLE]({ commit, dispatch }, params: { id: string, token: string }) {
            const { id, token } = params;
            const resposta = await http.auth.get(`/user/${id}/role`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            commit(SET_ROLE, resposta.data['data']['roles']);
        },
        async [UPDATE_ROLE]({ commit, state }, params: { id: string, data: any}) {
            const { id, data } = params;
            const resposta = await http.smartCity.put(`/user/role`, {
                userId: id,
                roleName: 'SMART_CITY',
                data
            },{
                headers: {
                    Authorization: `Bearer ${state.token.access_token}`
                }
            });
        },
        [GET_USERS_PERMISSION]({ commit }) {
            return http.smartCity.get('/permissions/all')
                .then(resposta => commit(DEFINE_PERMISSIONS, resposta.data['data']));
        }
    }
}