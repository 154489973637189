import { RouteRecordRaw } from "vue-router";
import List from '@/views/Dashboard/Tenants/List.vue';
import Form from '@/views/Dashboard/Tenants/Form.vue';

export const tenantRoutes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'Tenants',
        component: List
    },
    {
        path: 'novo',
        name: 'Novo Tenant',
        component: Form
    },
    {
        path: ':id',
        name: 'Editar Tenant',
        component: Form,
        props: true
    }
]