import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type == 'temperatura')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: "fa-solid fa-thermometer-half",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'corrente')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: "fa-solid fa-bolt",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'umidade')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 2,
          icon: "fa-solid fa-droplet",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'nivel')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 3,
          icon: "fa-solid fa-house-flood-water",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_ctx.type == '' || _ctx.type == 'contato')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 4,
          icon: "fa-solid fa-toggle-off",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'totalização')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 5,
          icon: "fa-solid fa-stopwatch-20",
          size: _ctx.size
        }, null, 8, ["size"]))
      : _createCommentVNode("", true)
  ], 64))
}