
import { HistoryAlarm } from '@/interfaces/Alarm';
import { defineComponent, PropType, computed } from 'vue';
import { faExclamation, faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamation, faCirclePlay, faClock);

export default defineComponent({
    name: 'AlarmCard',
    props: {
        alarm: {
            type: Object as PropType<HistoryAlarm>,
            required: true
        }
    },
    setup(props) {
        const isoDateToLocalDate = (isoDate: string | undefined) => {
            if(isoDate) {
                const date = Date.parse(isoDate);
                return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`;
            }

            return '';
        }
        return {
            startTime: computed(() => isoDateToLocalDate(props.alarm.startedAt)),
            endTime: computed(() => isoDateToLocalDate(props.alarm.endedAt))
        }
    }
});

