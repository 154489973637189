
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'TitleBar',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup(props) {
        return {
            titleUpperCase: computed(() => props.title.toLocaleUpperCase() )
        }
    }
});
