import { InjectionKey } from "vue";
import { createStore, Store, useStore as vuexUseStore } from "vuex";
import { structure, StructureState } from "./modules/structures";
import { tenant, TenantState } from "./modules/tenants";
import { user, UserState } from "./modules/users";
import User from "@/interfaces/User";
import { EndpointState, endpoint } from "./modules/endpoints";
import { SensorState, sensor } from "./modules/sensors";
import { TriggerState, trigger } from "./modules/triggers";
import { alarm, AlarmState } from "./modules/alarms";
import vuexPersistedState from 'vuex-plugin-persistedstate';
import { plugin } from "./plugin";
import UserRole from "@/interfaces/UserRole";
import { gateway, GatewayState } from "./gateways";
import { map, MapState } from "./modules/map";
import Structure from "@/interfaces/Structure";
import { ViewMap } from "@/interfaces/ViewMap";
import { billing, BillingState } from "./modules/billing";

export interface State {
    user: UserState;
    tenant: TenantState;
    structure: StructureState;
    endpoint: EndpointState;
    gateway: GatewayState;
    sensor: SensorState;
    trigger: TriggerState;
    alarm: AlarmState;
    map: MapState,
    billing: BillingState
}

export const key: InjectionKey<Store<State>> = Symbol();

const persistedState = vuexPersistedState();

export const store = createStore<State>({
    state: {
        user: {
            user: {} as User,
            users: [],
            token: {
                access_token: '',
                refresh_token: ''
            },
            userRole: []
        },
        tenant: {
            tenants: []
        },
        structure: {
            structures: [],
            sensors: [],
            gateways: []
        },
        endpoint: {
            endpoints: []
        },
        gateway: {
            gateways: []
        },
        sensor: {
            sensors: []
        },
        trigger: {
            triggers: []
        },
        alarm: {
            alarms: [],
            historyAlarms: []
        },
        map: {
            structure: {} as Structure,
            sensors: [],
            endpointSensors: [],
            map: {} as ViewMap
        },
        billing: {
            billings: []
        }
    },
    modules: {
        user,
        tenant,
        structure,
        endpoint,
        gateway,
        sensor,
        trigger,
        alarm,
        map,
        billing
    },
    plugins: [plugin]
    // plugins: [
    //     persistedState
    // ]
});

export function useStore(): Store<State> {
    return vuexUseStore(key);
}

