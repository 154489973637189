
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';
import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { GET_ENDPOINTS } from '@/store/action-types';
import Endpoint from '@/interfaces/Endpoint';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faTowerBroadcast);

export default defineComponent({
    name: 'EndpointSelect',
    emits: ['onSelectEndpoint'],
    setup(prop, { emit }) {
        const store = useStore();

        const { isMaster } = usePermissions();

        store.dispatch(GET_ENDPOINTS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });

        const selectEndpoint = (endpoint: Endpoint) => {
            emit('onSelectEndpoint', endpoint);
        };

        return {
            selectEndpoint,
            endpoints: computed(() => store.state.endpoint.endpoints)
        };
    }
});
