
import Structure from '@/interfaces/Structure';
import { computed, defineComponent, PropType, ref } from 'vue';
import { faFolder, faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { structure } from '@/store/modules/structures';
library.add(faFolder, faAngleDown, faAngleRight, faSquarePlus, faSquareMinus);

export default defineComponent({
    name: 'StructureItem',
    emits: ['onItemSelected'],
    props: {
        structures: {
            type: Object as PropType<Structure[] | undefined>,
            required: true
        },
        parent: {
            type: String,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const childrenIsVisible = ref(false);
        const itemSelected = ref('');

        const selectItem = (structure: Structure) => {
            itemSelected.value = structure.name;
            emit('onItemSelected', structure);
        }

        const cancelSelection = () => {
            itemSelected.value = '';
        }

        const children = (thisParent: string): Structure[] | undefined => {
            return props.structures?.filter(structure => structure.parent != props.parent && structure.parent == thisParent);
        };

        const hasChildren = (parent: string) => {
            return (props.structures?.filter(structure => structure.parent == parent && structure.type != 'sensor').length || 0) > 0
        };

        const toggleChildren = () => {
            childrenIsVisible.value = !childrenIsVisible.value;
        }

        return {
            parents: computed(() => props.structures?.filter(structure => structure.parent == props.parent && structure.type != 'sensor')),
            list: computed(() => props.structures?.filter(structure => structure.parent != props.parent)),
            childrenIsVisible,
            itemSelected,
            selectItem,
            cancelSelection,
            hasChildren,
            children,
            toggleChildren
        }
    }
});
