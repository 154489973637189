
import MonthPicker from '@/components/MonthPicker.vue';
import PageHeader from '@/components/PageHeader.vue';
import TitleBar from '@/components/TitleBar.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useTenants } from '@/composables/UseTenants';
import { useStructures } from '@/composables/UseStrucutures';
import { usePermissions } from '@/composables/UsePermissions';
import { Report } from '@/interfaces/Report';
import ReportCard from '@/components/ReportCard.vue';

export default defineComponent({
    name: "Report",
    components: { TitleBar, PageHeader, MonthPicker, ReportCard },
    setup() {
        const { isMaster } = usePermissions();
        const { userTenant } = useStructures();
        const { reports, getReports, tenants, getTenants } = useTenants(isMaster.value ? '' : userTenant.value);
        const actualReports = ref<Report[]>([]);
        const value = ref(new Date());
        getTenants();
        getReports();
        

        const handleChangedMonth = ({ startDate, endDate }: any) => {
            actualReports.value = reports.value?.filter(report => (startDate <= report.referenceDate && report.referenceDate <= endDate)) || [];
        }

        return {
            handleChangedMonth,
            isMaster,
            actualReports,
            tenants,
            value
        }
    }
});
