
import { computed, defineComponent, ref } from 'vue'
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import PermissionCard from '@/components/PermissionCard.vue';
import { useStore } from '@/store';
import { GET_USERS } from '@/store/action-types';
import Modal from '@/components/Modal.vue';
import User from '@/interfaces/User';
import StructureList from '@/components/structure/StructureList.vue';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faUser);

export default defineComponent({
    name: 'Permissions',
    components: {
        TitleBar,
        PageHeader,
        PermissionCard,
        Modal,
        StructureList
    },
    setup() {
        const store = useStore();
        store.dispatch(GET_USERS, store.state.user.token.access_token);
        const modalActive = ref(false);
        const modalStructuresActive = ref(false);
        const selectedUser = ref<User | null>(null);

        const showModal = (user: User) => {
            selectedUser.value = user;
            modalActive.value = true;
        }

        const showStructuresModal = () => {
            modalActive.value = false;
            modalStructuresActive.value = true;
        }

        const hideModal = () => {
            selectedUser.value = null;
            modalActive.value = false;
        }

        const hideStructuresModal = () => {
            modalStructuresActive.value = false;
            modalActive.value = true;
        }

        return {
            users: computed(() => store.state.user.users),
            modalActive,
            modalStructuresActive,
            selectedUser,
            showModal,
            showStructuresModal,
            hideModal,
            hideStructuresModal
        }
    }
});
