
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TitleCard',
    props: {
        title: {
            type: String,
            required: true
        }
    }
});
