import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f888756"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sensor" }
const _hoisted_2 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorIcon = _resolveComponent("SensorIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SensorIcon, {
        type: _ctx.sensor.type
      }, null, 8, ["type"])
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.sensor.name), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.value), 1)
  ]))
}