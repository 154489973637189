
import { defineComponent, onMounted, PropType, ref } from 'vue';

export default defineComponent({
    name: 'OperatorItem',
    props: {
        option: {
            type: Object as PropType<string | undefined>,
            required: true
        }
    },
    setup(props) {
        const operator = ref<HTMLDivElement | null>(null);

        onMounted(() => {
            if(props.option == 'OR') {
                operator.value!.style.backgroundColor = '#0A665F';
            } else {
                operator.value!.style.backgroundColor = '#01739F';
            }
        });

        return {
            operator
        }
    }
});
