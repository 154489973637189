
import { computed, defineComponent, ref } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import TriggerCard from '@/components/TriggerCard.vue';
import Modal from '@/components/Modal.vue';
import RuleCard from '@/components/trigger/RuleCard.vue';
import CheckBox from '@/components/misc/CheckBox.vue';
import { useStore } from '@/store';
import { Trigger } from '@/interfaces/Trigger';
import { faBell, faExclamationTriangle, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { GET_TRIGGERS, GET_USER_SENSORS } from '@/store/action-types';
import { usePermissions } from '@/composables/UsePermissions';
import { useStructures } from '@/composables/UseStrucutures';
library.add(faBell, faExclamationTriangle, faSquareCheck, faSquare);

export default defineComponent({
    name: 'Triggers',
    emits: ['onEditTriggerClick'],
    components: {
        TitleBar,
        PageHeader,
        TriggerCard,
        Modal,
        RuleCard,
        CheckBox
    },
    setup(props, { emit }) {
        const store = useStore();
        store.dispatch(GET_TRIGGERS);
        store.dispatch(GET_USER_SENSORS);
        const selectedTrigger = ref<Trigger>();
        const { isMaster } = usePermissions();
        const { userTenant } = useStructures();

        const showTriggerDetails = (trigger: Trigger) => {
            selectedTrigger.value = { ...trigger };
        };

        const closeTriggerDetails = () => {
            selectedTrigger.value = undefined;
        };

        const editTrigger = (trigger: Trigger | undefined) => {
            emit('onEditTriggerClick', trigger);
            closeTriggerDetails();
        };

        return {
            triggers: computed(() => {
                if(isMaster.value) {
                    return store.state.trigger.triggers;
                }

                return store.state.trigger.triggers?.filter(trigger => trigger.tenant == userTenant.value);
            }),
            sendTo: computed(() => store.state.user.users.filter(user => selectedTrigger.value?.sendTo?.includes(user.email)).map(user => user.username).join(', ')),
            showTriggerDetails,
            closeTriggerDetails,
            editTrigger,
            selectedTrigger
        }
    }
});
