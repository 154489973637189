import { store } from "@/store";
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from '../views/Dashboard.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Tenants from '../views/Dashboard/Tenants.vue';
import Monitor from '../views/Dashboard/Monitor.vue';
import Structures from '../views/Dashboard/Structures.vue';
import Endpoints from '../views/Dashboard/Endpoints.vue';
import Sensors from '../views/Dashboard/Sensors.vue';
import Users from '@/views/Dashboard/Users.vue';
import Permissions from '@/views/Dashboard/Permissions.vue';
import Triggers from '@/views/Dashboard/Triggers.vue';
import Alarms from '@/views/Dashboard/Alarms.vue';
import Map from '@/views/Dashboard/Map.vue';
import ViewMap from '@/views/ViewMap.vue';
import { tenantRoutes } from "./tenant";
import Billing from '@/views/Dashboard/Billing.vue';
import Report from '@/views/Dashboard/Report.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/dashboard/structure',
        redirect: '/login'
    },
    {
        path: '/dashboard',
        redirect: '/login'
    },
    {
        path: '/view-map',
        children: [
            {
                path: ':id',
                name: 'ViewMap',
                component: ViewMap,
                props: true
            }
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'dashboard',
                component: Dashboard,
                children: [
                    {
                        path: 'tenant',
                        component: Tenants,
                        children: tenantRoutes
                    },
                    {
                        path: 'structure',
                        children: [
                            {
                                path: ':id',
                                name: 'Structures',
                                component: Structures,
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'map',
                        children: [
                            {
                                path: ':id',
                                name: 'Map',
                                component: Map,
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'monitor',
                        component: Monitor,
                        name: 'Monitor'
                    },
                    {
                        path: 'endpoint',
                        component: Endpoints,
                        name: 'Endpoints'
                    },
                    {
                        path: 'sensor',
                        component: Sensors,
                        name: 'Sensors'
                    },
                    {
                        path: 'user',
                        component: Users,
                        name: 'Users'
                    },
                    {
                        path: 'permission',
                        component: Permissions,
                        name: 'Permissions'
                    },
                    {
                        path: 'trigger',
                        component: Triggers,
                        name: 'Triggers'
                    },
                    {
                        path: 'alarm',
                        component: Alarms,
                        name: 'Alarms'
                    },
                    {
                        path: 'billing',
                        children: [
                            {
                                path: 'sensors',
                                component: Billing,
                                name: 'Billing'
                            },
                            {
                                path: 'report',
                                component: Report,
                                name: 'Report'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/home',
        redirect: '/'
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/*',
        redirect: '/login'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach((to, from, next) => {
    const user = store.state.user.user;
    const role = store.state.user.userRole;
    const unauthorized = !user && (!role || role.length == 0 || role.filter(r => r.name == 'SMART_CITY').length < 1);


    if((to.name != 'Login' && unauthorized) && to.name != 'ViewMap') next( {name: 'Login'});
    else next();
    
});

export default router;