
import { defineComponent, onMounted, ref, watch } from 'vue';
import Modal from '../Modal.vue';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { usePermissions } from '@/composables/UsePermissions';
import CheckBox from '../misc/CheckBox.vue';
import { computed } from '@vue/reactivity';
import { GET_GATEWAYS, UPDATE_STRUCTURE } from '@/store/action-types';
library.add(faNetworkWired);
export default defineComponent({
    name: 'ModalSetGateways',
    emits: ['onCloseClick'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        structure: {
            type: String,
            required: true
        }
    },
    components: {
        Modal,
        CheckBox
    },
    setup(props, { emit }) {
        const store = useStore();
        const selectedStructure = ref<Structure>({} as Structure);
        const selectedGateways = ref<string[]>([]);

        const close = () => {
            selectedStructure.value = {} as Structure;
            selectedGateways.value = [];
            emit('onCloseClick');
        };

        const save = (structure: string, gateways: string[]) => {
            const structureToUpdate = store.state.structure.structures.find(s => s.id == structure);
            structureToUpdate!.gateways = gateways;
            store.dispatch(UPDATE_STRUCTURE, structureToUpdate);
            close();
        }

        store.dispatch(GET_GATEWAYS);
        const { gateways } = usePermissions();

        const selectGateway = (gateway: string) => {
            if(selectedGateways.value.includes(gateway)) {
                selectedGateways.value.splice(selectedGateways.value.indexOf(gateway), 1);
                return;
            }

            selectedGateways.value.push(gateway);
        };

        watch(() => 
            props.structure,
            (value, old) => {
                selectedStructure.value = store.state.structure.structures.find(structure => structure.id == value) || {} as Structure;
                selectedGateways.value = selectedStructure.value.gateways || [];
            }
        )

        return {
            selectedStructure,
            selectedGateways,
            close,
            gateways: computed(() => store.state.gateway.gateways?.filter(g => gateways.value.includes(g.id))),
            selectGateway,
            save
        }
    }
})
