import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52ee9af0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-text" }
const _hoisted_3 = { class: "title-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ])
  ]))
}