
import { computed, defineComponent, onMounted, PropType, ref, watchEffect } from 'vue';
import Modal from '@/components/Modal.vue';
import ModalRule from './Rule/ModalRule.vue';
import RuleCard from '@/components/trigger/RuleCard.vue';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Rule, Trigger } from '@/interfaces/Trigger';
import SelectType from './Type/SelectType.vue';
import { useStore } from '@/store';
import SelectUser from './User/SelectUser.vue';
import { useStructures } from '@/composables/UseStrucutures';
import { CREATE_TRIGGER, GET_TRIGGERS, UPDATE_TRIGGER } from '@/store/action-types';
library.add(faScrewdriverWrench, faCirclePlus);

export default defineComponent({
    name: 'ModalTrigger',
    emits: ['onCloseModal'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        trigger: {
            type: Object as PropType<Trigger>
        }
    },
    components: {
        Modal,
        SelectType,
        ModalRule,
        RuleCard,
        SelectUser
    },
    setup(props, { emit }) {
        const store = useStore();
        const showRuleModal = ref(false);
        const { userTenant } = useStructures();

        const formTrigger = ref({
            id: undefined as string | undefined,
            name: '',
            description: '',
            type: '',
            rules: [] as Rule[],
            actions: [] as string[],
            message: '',
            createdBy: store.state.user.user.name,
            tenant: '',
            active: true as boolean
        });

        const formSendTo = ref<string[] | undefined>([]);

        const closeModal = () => {
            clearForm();
            store.dispatch(GET_TRIGGERS);
            emit('onCloseModal');
        };

        const save = () => {
            const trigger = {
                ...formTrigger.value,
                sendTo: formSendTo.value,
                tenant: userTenant
            }
            
            if(trigger.id) {
                store.dispatch(UPDATE_TRIGGER, trigger);
            } else {
                store.dispatch(CREATE_TRIGGER, trigger);
            }
            
            closeModal();
        };

        const removeRule = (index: number) => {
            formTrigger.value.rules.splice(index, 1);
        }

        const selectType = (type: string) => {
            formTrigger.value.type = type;
        };

        const clearForm = () => {
            formTrigger.value = {
                id: undefined as string | undefined,
                name: '',
                description: '',
                type: '',
                rules: [] as Rule[],
                actions: [],
                message: '',
                createdBy: store.state.user.user.name,
                tenant: '',
                active: true as boolean
            };
        };

        const addRule = () => {
            showRuleModal.value = true;
        };

        const closeRuleModal = () => {
            showRuleModal.value = false;
        };

        const addSendTo = (sendTo: string[]) => {
            formSendTo.value = sendTo;
        };

        const saveRule = (rule: Rule) => {
            formTrigger.value.rules.push(rule);
            closeRuleModal();
        };

        watchEffect(() => {
            if(props.trigger) {
                const { createdAt, modifiedAt, deleted, sendTo, id, ...trigger } = props.trigger;
                formTrigger.value = { id, ...trigger };
                formSendTo.value = sendTo;

            }
        });

        return {
            closeModal,
            save,
            formTrigger,
            selectType,
            addRule,
            closeRuleModal,
            showRuleModal,
            saveRule,
            addSendTo,
            formSendTo,
            removeRule,
            sensors: computed(() => store.state.structure.structures.filter(structure=> structure.type == 'sensor'))
        }
    }
});
