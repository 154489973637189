
import Structure from '@/interfaces/Structure';
import { PropType, defineComponent, ref, watch, watchEffect } from 'vue';
import { faAddressCard, faTags, faMoneyBillWave, faAnglesRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import TenantBillingCard from './TenantBillingCard.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Billing } from '@/interfaces/Billing';
library.add(faAddressCard, faTags, faMoneyBillWave, faAnglesRight, faAngleDown);

export default defineComponent({
    name: 'StructureTenantCard',
    props: {
        tenant: {
            type: Object as PropType<Structure>,
            required: true
        },
        sensors: {
            type: Number,
            default: 0
        },
        billings: {
            type: Object as PropType<Billing[]>,
            required: true
        },
        monthDate: {
            type: Date
        }
    },
    components: {
        TenantBillingCard
    },
    setup(props) {
        const showingDetails = ref(false);
        const thisBillings = ref<Billing[]>();

        const showDetails = () => {
            if(props.sensors > 0) {
                showingDetails.value = !showingDetails.value;
            }
        }

        watch(() => props.billings, 
            (value, old) => {
                console.log('Billings :', value);  
                thisBillings.value = value;  
            }
        );

        return {
            showDetails,
            showingDetails,
            thisBillings
        }
    }
});
