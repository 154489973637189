
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { ADD_STRUCTURE, CREATE_USER, GET_GATEWAYS, GET_SENSORS, GET_USERS, UPDATE_ROLE, UPDATE_STRUCTURE, UPLOAD_FILE } from '@/store/action-types';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import LeftMenu from '../components/LeftMenu.vue';
import TopBar from '../components/TopBar.vue';
import Modal from '@/components/Modal.vue';
import SensorIcon from '@/components/misc/SensorIcon.vue';
import SensorSelectCard from '@/components/misc/SensorSelectCard.vue';
import ModalTrigger from './Dashboard/Trigger/ModalTrigger.vue';
import { faLink, faEllipsis, faFolderPlus, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faSquarePlus, faSquareMinus, faFolder } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Sensor from '@/interfaces/Sensor';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { Trigger } from '@/interfaces/Trigger';
import { usePermissions } from '@/composables/UsePermissions';
import { useStructures } from '@/composables/UseStrucutures';
import ModalSetStructure from '@/components/structure/ModalSetStructure.vue';
import ModalSetGateways from '@/components/structure/ModalSetGateways.vue';
import ModalCreateUser from './Dashboard/Trigger/User/ModalCreateUser.vue';
import CheckBox from '@/components/misc/CheckBox.vue';
import FileUpload from '@/components/FileUpload.vue';
library.add(faLink, faEllipsis, faSquarePlus, faSquareMinus, faFolderPlus, faFolder, faCloudArrowUp);

export default defineComponent({
    name: 'Dashboard',
    components: {
        LeftMenu,
        TopBar,
        Modal,
        SensorIcon,
        SensorSelectCard,
        ModalTrigger,
        ModalSetStructure,
        ModalCreateUser,
        ModalSetGateways,
        CheckBox,
        FileUpload
    },
    setup() {
        const store = useStore();
        const addStructure = ref(false);
        const newSensor = ref(false);
        const newUser = ref(false);
        const newTrigger = ref(false);
        const selectedStructure = ref('root');
        const form = ref({ type: '' } as Structure);
        const formSensor = ref({
            type: '',
            mapped: {
                true: '',
                false: ''
            }
        } as Structure);
        const formUser = ref({
            name: '',
            username: '',
            email: '',
            role: []
        });
        const formRoles = ref<string>('');
        const sensormenu = ref<HTMLDivElement | null>(null);
        const linkSensor = ref<HTMLDivElement | null>(null);
        const sensors = computed(() => store.state.sensor.sensors);
        const selectedSensor = ref<Sensor | null>(null);
        const structureForDetail = ref<Structure | null>(null);
        const parentStructure = ref<Structure | undefined>();
        const selectedTrigger = ref<Trigger>();
        const showAssignModal = ref(false);
        const showGatewayModal = ref(false);
        const selectedGateways = ref<string[]>([]);
        const { isMaster, root } = usePermissions();
        const { userTenant } = useStructures();
        const loadedFile = ref<File | undefined>();
        const loadedFilename = ref('');

        store.dispatch(GET_SENSORS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });
        store.dispatch(GET_GATEWAYS);

        const showModal = () => {
            addStructure.value = true;
        };

        const showNewSensorModal = () => {
            selectedSensor.value = null;
            formSensor.value = { type: '', mapped: { true: '', false: ''}} as Structure;
            newSensor.value = true;
        };

        const showNewTriggerModal = () => {
            newTrigger.value = true;
        };

        const closeNewTriggerModal = () => {
            newTrigger.value = false;
            selectedTrigger.value = undefined;
        };

        const showModalUser = () => {
            newUser.value = true;
        };

        const closeModalUser = () => {
            newUser.value = false;
            
            formUser.value = {
                name: '',
                username: '',
                role: [],
                email: ''
            }
        };

        const saveUser = (user: any, role: string) => {
            user.role.push({
                name: 'SMART_CITY',
                data: {
                    role: role,
                } 
            });
            store.dispatch(CREATE_USER, user);
            closeModalUser();
        };

        const closeNewSensorModal = () => {
            hideSensorMenu();
            newSensor.value = false;
        };

        const closeModal = () => {
            addStructure.value = false;
            form.value = { type: '', mapped: { true: '', false: ''}} as Structure;
            selectedGateways.value = [];
            loadedFile.value = undefined;
        };

        const showDetailModal = (structure: Structure) => {
            structureForDetail.value = structure;
            if(structure.file) {
                loadedFilename.value = structure.file
            }
        };

        const closeDetailModal = () => {
            structureForDetail.value = null;
            loadedFile.value = undefined;
            loadedFilename.value = '';
        };

        const selectStructure = (id: string, structure: Structure | undefined) => {
            parentStructure.value = structure;
            selectedStructure.value = id;
        };

        const selectSensor = (sensor: Sensor) => {
            selectedSensor.value = sensor;
            hideSensorMenu();
        };

        const editSensor = (sensor: Structure) => {
            formSensor.value = { ...sensor };
            selectedSensor.value = store.state.sensor.sensors.find(s => s.id == sensor.sensor) || null;
            newSensor.value = true;
        };

        const editStructure = (structure: Structure) => {
            form.value = { ...structure };
            selectedGateways.value = structure.gateways || [];
            showModal();
        };

        const toggleNormalization = () => {
            if(formSensor.value.normalization) {
                formSensor.value.normalization = undefined;
            } else {
                formSensor.value.normalization = {
                    inputMin: 0,
                    inputMax: 0,
                    outputMin: 0,
                    outputMax: 0
                }
            }
        }

        const openSensorMenu = (event: MouseEvent) => {
            if(!sensormenu.value || !linkSensor.value) {
                return;
            }

            const element: HTMLDivElement = event.target as HTMLDivElement;

            const { width, height } = sensormenu.value.getBoundingClientRect();

            const x = linkSensor.value.getBoundingClientRect().x;
            const y = linkSensor.value.getBoundingClientRect().y + linkSensor.value.getBoundingClientRect().height;

            sensormenu.value.style.width = `${linkSensor.value.getBoundingClientRect().width}px`;
            sensormenu.value.style.left = `${x}px`;
            sensormenu.value.style.top = `${y}px`;

            showSensorMenu();
        };

        const hideSensorMenu = () => {
            if(sensormenu.value) {
                sensormenu.value.style.display = 'none';
            }
        };

        const showSensorMenu = () => {
            if(sensormenu.value) {
                sensormenu.value.style.display = 'block';
            }
        };

        const showLocalDateTime = (data: string) => {
            const date = new Date(Date.parse(data));
            return date.toLocaleDateString() + " " + date.toLocaleTimeString();
        }

        const saveStructure = async (structure: Structure) => {
            if(structure.id) {
                store.dispatch(UPDATE_STRUCTURE, structure);
            } else {
                if(loadedFile.value) {
                    const uploaded = await store.dispatch(UPLOAD_FILE, loadedFile.value);
                    structure.file = uploaded;
                }
                structure.parent = selectedStructure.value;
                structure.createdBy = store.state.user.user.name;
                store.dispatch(ADD_STRUCTURE, { structure, tenant: userTenant.value });
            }
            
            closeModal();
        };

        const upload = async (structure: Structure) => {
            if(loadedFile.value) {
                const uploaded = await store.dispatch(UPLOAD_FILE, loadedFile.value);
                structure.file = uploaded;
                store.dispatch(UPDATE_STRUCTURE, structure);
            }
        }

        const saveSensor = (sensor: Structure) => {
            if(sensor.id) {
                sensor.sensor = selectedSensor.value?.id;
                store.dispatch(UPDATE_STRUCTURE, sensor);
            } else {
                sensor.parent = selectedStructure.value;
                sensor.type = 'sensor';
                sensor.createdBy = store.state.user.user.name;
                sensor.sensor = selectedSensor.value?.id;
                sensor.position = {
                    left: 0,
                    top: 0,
                    show: false
                }
                store.dispatch(ADD_STRUCTURE, { structure: sensor, tenant: userTenant.value });
            }
            formSensor.value = { type: '', mapped: { true: '', false: ''}} as Structure;
            closeNewSensorModal();
        };

        const editTrigger = (trigger: Trigger) => {
            selectedTrigger.value = trigger;
            newTrigger.value = true;
        };

        const showAssignToStructure = (structure: string) => {
            selectedStructure.value = structure;
            showAssignModal.value = true;
        };

        const showDefineGatewayModal = (structure: string) => {
            selectedStructure.value = structure;
            showGatewayModal.value = true;
        };

        const hideAssignToStructure = () => {
            selectedStructure.value = '';
            showAssignModal.value = false;
        };

        const hideDefineGatewayModal = () => {
            selectedStructure.value = '';
            showGatewayModal.value = false;
        };

        const savePermissions = async (data: { structure: string, users: string[] }) => {
            const promises: Promise<any>[] = [];
            for(var user of data.users) {
                promises.push(store.dispatch(UPDATE_ROLE, { 
                    id: user, 
                    token: store.state.user.token.access_token, 
                    data: { 
                        structure: data.structure 
                    }
                }));
            }

            await Promise.all(promises);
            store.dispatch(GET_USERS, store.state.user.token.access_token);
        };

        const selectGateway = (gateway: string) => {
            if(selectedGateways.value.includes(gateway)) {
                selectedGateways.value.splice(selectedGateways.value.indexOf(gateway));
                return;
            }

            selectedGateways.value.push(gateway);
            form.value.gateways = selectedGateways.value;
        };

        const selectFile = (file: File) => {
            loadedFile.value = file;
        }

        onBeforeRouteUpdate((to, from, next) => {
            if(to.name != 'Structures') {
                parentStructure.value = undefined;
            }
            next();
        });

        return {
            addStructure,
            newSensor,
            selectedStructure,
            form,
            formSensor,
            sensormenu,
            selectedSensor,
            linkSensor,
            showNewSensorModal,
            showModal,
            closeNewSensorModal,
            closeModal,
            showNewTriggerModal,
            closeNewTriggerModal,
            newTrigger,
            selectStructure,
            saveStructure,
            openSensorMenu,
            selectSensor,
            sensors,
            toggleNormalization,
            saveSensor,
            editSensor,
            editStructure,
            structureForDetail, 
            showDetailModal,
            closeDetailModal,
            showLocalDateTime,
            parentStructure,
            newUser,
            showModalUser,
            closeModalUser,
            saveUser,
            editTrigger,
            formUser,
            formRoles,
            selectedTrigger,
            showAssignToStructure,
            hideAssignToStructure,
            showDefineGatewayModal,
            hideDefineGatewayModal,
            showAssignModal,
            savePermissions,
            showGatewayModal,
            root,
            selectedGateways,
            selectFile,
            loadedFile,
            loadedFilename,
            selectGateway,
            upload,
            type: computed(() => {
                if(structureForDetail.value) {
                    const str = structureForDetail.value?.type.slice(1);
                    return structureForDetail.value?.type.charAt(0).toLocaleUpperCase() + str;
                }

                return '';
            }),
            gateways: computed(() => store.state.gateway.gateways)
        }
    }
});
