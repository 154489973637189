
import { computed, defineComponent, PropType } from 'vue';
import TitleCard from './TitleCard.vue';
import Sensor from '@/interfaces/Sensor';
import SensorIcon from './SensorIcon.vue';
import { faTowerBroadcast, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useStore } from '@/store';
library.add(faTowerBroadcast, faCalendarDays);

export default defineComponent({
    name: 'SensorCard',
    props: {
        sensor: {
            type: Object as PropType<Sensor>,
            required: true
        }
    },
    components: {
        TitleCard,
        SensorIcon
    },
    setup(props) {
        const store = useStore();

        return {
            endpoint: computed(() => store.state.endpoint.endpoints?.find(e => e.id = props.sensor.endpoint)?.name),
            lastMessage: computed(() => { 
                let date = new Date(props.sensor.timestamp * 1000);
                return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
            }),
            sensorValue: computed(() => {
                if(typeof props.sensor.value == 'number') {
                    if(props.sensor.type == 'corrente') {
                        return props.sensor.value.toFixed(4);
                    }

                    return props.sensor.value.toFixed(1);
                }

                return props.sensor.value;
            })
        }
    }
});
