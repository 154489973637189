
import { defineComponent, PropType } from 'vue';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSquareXmark, faSquare);

export default defineComponent({
    name: 'CheckBox',
    emits: ['click'],
    props: {
        checked: {
            type: Object as PropType<boolean | undefined>,
            required: true
        }
    },
    setup(props, { emit }) {
        const click = () => {
            emit('click');
        }

        return {
            click
        }
    }
});
