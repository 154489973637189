import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StructureItem = _resolveComponent("StructureItem")!

  return (_openBlock(), _createBlock(_component_StructureItem, {
    structures: _ctx.structures,
    parent: "root",
    isVisible: true,
    onOnItemSelected: _ctx.selectItem
  }, null, 8, ["structures", "onOnItemSelected"]))
}