import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ecc788f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tower-broadcast" })
    ]),
    _createTextVNode(_toDisplayString(_ctx.endpoint.name), 1)
  ]))
}