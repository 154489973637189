
import { useStore } from '@/store';
import { GET_TENANTS } from '@/store/action-types';
import { computed, ref } from '@vue/reactivity';
import { defineComponent, onMounted, onUnmounted } from 'vue';

export default defineComponent({
    name: 'SelectTenant',
    emits: ['onOptionClick'],
    props: {
        tenant: {
            type: String,
        }
    },
    setup(props, { emit }) {
        const store = useStore();

        const select = ref<HTMLDivElement>();
        const options = ref<HTMLDivElement>();

        store.dispatch(GET_TENANTS, 'root');

        const showOptions = () => {
            if(options.value) {
                options.value.style.display = 'block';
            }
        };

        const closeOptions = () => {
            if(options.value) {
                options.value.style.display = 'none';
            }
        };

        const showOptionsDropDown = () => {
            if(!select.value || !options.value) {
                return;
            }

            const x = select.value.offsetLeft;
            const y = select.value.offsetTop + select.value.getBoundingClientRect().height;
            const { width } = select.value.getBoundingClientRect();

            options.value.style.left = `${x}px`;
            options.value.style.top = `${y}px`;
            options.value.style.width = `${width}px`;

            showOptions();
        };

        const windowClick = (event: MouseEvent) => {
            if(!select.value?.contains(event.target as Node) && !options.value?.contains(event.target as Node)) {
                closeOptions();
            }
        };

        const optionClick = (id: string) => {
            closeOptions();
            emit('onOptionClick', id);
        };

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        const tenants = computed(() => store.state.tenant.tenants);

        return {
            showOptionsDropDown,
            select,
            options,
            optionClick,
            selectedTenant: computed(() => store.state.tenant.tenants.find(tenant => tenant.id == props.tenant)?.name),
            tenants
        }
    }
})
