import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c5d29fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sensor" }
const _hoisted_2 = { class: "sensor-data" }
const _hoisted_3 = { class: "sensor-icon" }
const _hoisted_4 = { class: "sensor-value" }
const _hoisted_5 = { class: "sensor-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorIcon = _resolveComponent("SensorIcon")!
  const _component_SensorValue = _resolveComponent("SensorValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SensorIcon, {
          type: _ctx.structure.sensorType || ''
        }, null, 8, ["type"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SensorValue, { sensor: _ctx.structure }, null, 8, ["sensor"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.structure.name), 1)
  ]))
}