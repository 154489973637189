import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8013b70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sensor-connection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorIcon = _resolveComponent("SensorIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SensorIcon, {
      type: _ctx.sensorType || ''
    }, null, 8, ["type"]),
    _createTextVNode(" " + _toDisplayString(_ctx.sensorName), 1)
  ]))
}