
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import SensorMap from '@/components/SensorMap.vue';
import { useStore } from '@/store';
import { CREATE_VIEW_MAP, GET_USER_SENSORS, UPDATE_STRUCTURE } from '@/store/action-types';
import Structure from '@/interfaces/Structure';
import { faSliders, faToggleOn, faToggleOff, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Modal from '@/components/Modal.vue';
import SensorIcon from '@/components/SensorIcon.vue';
library.add(faSliders, faToggleOff, faToggleOn, faShareNodes);

export default defineComponent({
    name: 'Map',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        TitleBar,
        PageHeader,
        SensorMap,
        Modal,
        SensorIcon
    },
    setup(props) {
        const store = useStore();
        store.dispatch(GET_USER_SENSORS);
        const structure = computed(() => store.state.structure.structures?.find(structure => structure.id == props.id) || { position: { left: 0, top: 0 }} as Structure);
        const sensors = computed(() => store.state.structure.sensors?.filter(sensor => sensor.parent == structure.value?.id));
        const showSensors = computed(() => sensors.value?.filter(sensor => sensor.position?.show));
        const settings = ref(false);

        const image = computed(() => {
            if(structure.value) {
                return `${process.env.VUE_APP_SERVER_URI}/images/${structure.value.file!}`;
            }

            return '';
        })

        const containerElement = ref<HTMLElement | null>(null);
        const containerWidth = computed(() => containerElement.value?.getBoundingClientRect().width || 0);
        const containerHeight = computed(() => containerElement.value?.getBoundingClientRect().height || 0);
        let isDragging = false;
        let initialX = 0;
        let initialY = 0;
        let offsetX = 0;
        let offsetY = 0;
        let currentItem: string | null = null;

        const handleMouseDown = (event: MouseEvent, id: string) => {
            currentItem = id;
            isDragging = true;

            const draggableItem = sensors.value?.find(item => item.id == id);

            if(draggableItem) {
                initialX = event.clientX - draggableItem.position!.left * containerWidth.value;
                initialY = event.clientY - draggableItem.position!.top * containerHeight.value;
            }
        };

        const handleMouseMove = (event: MouseEvent) => {
            if(!isDragging || currentItem == null) {
                return;
            }

            event.preventDefault();

            const { clientX, clientY } = event;
            const containerRect = containerElement.value?.getBoundingClientRect();
            const draggableItem = sensors.value?.find(item => item.id == currentItem);

            if(containerRect && draggableItem) {
                const containerLeft = containerElement.value!.clientLeft;
                const containerTop = containerElement.value!.clientTop;
                const containerRight = containerElement.value!.clientLeft + containerElement.value!.clientWidth;
                const containerBottom = containerElement.value!.clientTop + containerElement.value!.clientHeight;
                const draggableWidth = 60;
                const draggableHeight = 60;

                let left = clientX - initialX;
                let top = clientY - initialY;

                if (left < containerLeft) {
                    left = containerLeft;
                } else if (left + draggableWidth > containerRight) {
                    left = containerRight - draggableWidth;
                }

                if (top < containerTop) {
                    top = containerTop;
                } else if (top + draggableHeight > containerBottom) {
                    top = containerBottom - draggableHeight;
                }

                draggableItem.position!.left = (left / containerElement.value!.getBoundingClientRect().width);
                draggableItem.position!.top = (top / containerElement.value!.getBoundingClientRect().height);

                offsetX = clientX - initialX;
                offsetY = clientY - initialY;
            }
        };

        const updateSensorPosition = (sensor: Structure) => {
            store.dispatch(UPDATE_STRUCTURE, sensor);
        }

        const handleMouseUp = () => {
            const sensor = sensors.value?.find(sensor => sensor.id == currentItem);

            if(sensor) {
                updateSensorPosition(sensor);
            }
            isDragging = false;
            currentItem = null;
        };

        const showSettings = () => {
            settings.value = true;
        };

        const hideSettings = () => {
            settings.value = false;
        };

        const toggleDisplay = (sensor: Structure) => {
            sensor.position!.show = !sensor.position!.show;
            if(!sensor.position?.show) {
                sensor.position!.left = 0;
                sensor.position!.top = 0;
            }

            store.dispatch(UPDATE_STRUCTURE, sensor);
        };

        const shareLink = async (structure: string) => {
            const data = {
                structure
            }

            const id = await store.dispatch(CREATE_VIEW_MAP, data);
            const texto = `http://localhost:8081/view-map/${id}`;

            navigator.clipboard.writeText(texto)
                .then(() => {
                    alert('Link copiado para a área de transferência');
                })
                .catch((error) => {
                    console.error('Erro ao copiar o link ', error);
                });
        }

        onMounted(() => {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        });

        onUnmounted(() => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        });

        return {
            structure,
            image,
            containerElement,
            handleMouseDown,
            sensors,
            showSensors,
            settings,
            showSettings,
            hideSettings,
            toggleDisplay,
            shareLink,
            containerHeight,
            containerWidth
        }
    }
});

