
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'EndpointItem',
    props: {
        text: {
            type: String,
            required: true
        },
        value: {
            type: Object as PropType<string | number | boolean>,
            required: true
        }
    }
});
