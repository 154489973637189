
import Endpoint from '@/interfaces/Endpoint';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
    name: 'EndpointOption',
    props: {
        endpoint: {
            type: Object as PropType<Endpoint>,
            required: true,
        }
    }
});
