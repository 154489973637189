export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_TENANTS = 'GET_TENANTS';
export const GET_STRUCTURES = 'GET_STRUCTURES';
export const GET_USER_SENSORS = 'GET_USER_SENSORS';
export const ADD_STRUCTURE = 'ADD_STRUCTURE';
export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE';
export const USER_AUTHENTICATE = 'USER_AUTHENTICATE';
export const GET_USER_PAYLOAD = 'GET_USER_PAYLOAD';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_ROLE = 'GET_USER_ROLE';
export const DELETE_STRUCTURE = 'DELETE_STRUCTURE';
export const GET_ENDPOINTS = 'GET_ENDPOINTS';
export const GET_SENSORS = 'GET_SENSORS';
export const GET_USERS_PERMISSION = 'GET_USERS_PERMISSION';
export const GET_TRIGGERS = 'GET_TRIGGERS';
export const CREATE_TRIGGER = 'CREATE_TRIGGER';
export const UPDATE_TRIGGER = 'UPDATE_TRIGGER';
export const GET_USER_TENANT = 'GET_USER_TENANT';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_GATEWAYS = 'GET_GATEWAYS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_IMAGE = 'GET_IMAGE';
export const CREATE_VIEW_MAP = 'CREATE_VIEW_MAP';
export const GET_VIEW_MAP = 'GET_VIEW_MAP';
export const GET_VIEW_MAP_SENSORS = 'GET_VIEW_MAP_SENSORS';
export const GET_ALARM_HISTORY = 'GET_ALARM_HISTORY';
export const CREATE_BILLING = 'CREATE_BILLING';
export const GET_BILLING = 'GET_BILLING';
export const DELETE_BILLING = 'DELETE_BILLING';
export const GET_PARENTS = 'GET_PARENTS';