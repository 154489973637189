
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import TriggerType from './TriggerType.vue';

export default defineComponent({
    name: 'SelectType',
    emits: ['onSelectType'],
    props: {
        selectedType: {
            type: Object as PropType<string | undefined>,
            required: true
        }
    },
    components: {
        TriggerType
    },
    setup(props, { emit }) {
        const selectType = ref<HTMLDivElement | null>(null);
        const type = ref<HTMLDivElement | null>(null);

        const showDropDownType = () => {
            if(!type.value || !selectType.value) {
                return;
            }

            const width = selectType.value.getBoundingClientRect().width;

            type.value.style.width = `${width}px`;

            showTypeOptions();
        };

        const showTypeOptions = () => {
            type.value!.style.display = 'block';
        };

        const hideTypeOptions = () => {
            type.value!.style.display = 'none';
        };

        const select = (type: string) => {
            hideTypeOptions();
            emit('onSelectType', type);
        };

        const windowClick = (event: MouseEvent) => {
            const element = event.target as HTMLDivElement;
            if(!selectType.value?.contains(element)) {
                hideTypeOptions();
            }
        }; 

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        return {
            selectType,
            type,
            showDropDownType,
            select,
            hideTypeOptions
        }
    }
});
