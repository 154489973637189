
import { defineComponent } from 'vue'
import { faThermometerHalf, faBolt, faDroplet, faHouseFloodWater, faToggleOff, faStopwatch20 } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faThermometerHalf, faBolt, faDroplet, faHouseFloodWater, faToggleOff, faStopwatch20);

export default defineComponent({
    name: 'SensorIcon',
    props: {
        type: {
            type: String,
            required: true
        },
        size: {
            type: String,
        }
    }
})
