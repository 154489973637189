
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ActiveIcon',
    props: {
        active: {
            type: Boolean,
            required: true
        }
    }
})
