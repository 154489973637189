
import { defineComponent, ref, onMounted } from 'vue';
import Datepicker from 'vuejs3-datepicker';

export default defineComponent({
    name: 'MonthPicker',
    props: {
        value: {
            type: Date,
        }
    },
    components: {
        Datepicker
    },
    emits: ['changeMonth'],
    setup(props, { emit }) {
        const selectedDate = ref<Date>();
        const handleChangedMonth = ({ timestamp }: any) => {
            const date = new Date(timestamp);
            selectedDate.value = new Date(timestamp);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            const startDate = date.getTime();
            date.setMonth(date.getMonth() + 1);
            date.setTime(date.getTime() - 1000);
            const endDate = date.getTime();
            emit('changeMonth', {
                startDate,
                endDate
            });
        }

        onMounted(() => {
            handleChangedMonth({ timestamp: props.value?.getTime() });
        })

        return {
            handleChangedMonth,
            selectedDate
        }
    }
});

