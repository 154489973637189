
import { computed, defineComponent, ref, watch } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import StructureTenantCard from '@/components/StructureTenantCard.vue';
import Datepicker from 'vuejs3-datepicker';
import { useTenants } from '@/composables/UseTenants';
import { usePermissions } from '@/composables/UsePermissions';
import { useStructures } from '@/composables/UseStrucutures';

export default defineComponent({
    name: "Billing",
    components: { 
        PageHeader,
        TitleBar,
        Datepicker,
        StructureTenantCard
     },
     setup() {
        const selectedDate = ref<Date>();
        const date = new Date();
        date.setDate(1);

        const { userTenant } = useStructures();
        const { isMaster } = usePermissions();
        const { tenants, billings, getTenants, getBillingInfo } = useTenants( isMaster.value ? '' : userTenant.value);

        getTenants();

        const handleChangedMonth = async ({ month, timestamp }: any) => {
            const date = new Date(timestamp);
            selectedDate.value = new Date(timestamp);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            const startDate = date.getTime();
            date.setMonth(date.getMonth() + 1);
            date.setTime(date.getTime() - 1000);
            const endDate = date.getTime();

            await getBillingInfo(startDate, endDate);
        }

        handleChangedMonth({timestamp: date.getTime()});

        const sensorsCount = (tenant: string) => {
            return computed(() => {
                return billings.value?.filter(billing => billing.tenant == tenant).length || 0;
            })
        }

        return {
            selectedDate,
            handleChangedMonth,
            tenants,
            billings,
            sensorsCount
        }
     }
})
