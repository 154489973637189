export const useFunctions = () => {
    const convertToLocalDateTime = (timestamp?: number) => {
        if(!timestamp) {
            return '';
        }
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }

    const convertToLocalDate = (timestamp?: number) => {
        if(!timestamp) {
            return '';
        }

        const date = new Date(timestamp);
        return `${date.toLocaleDateString()}`;
    }

    const convertToLocalMonthYear = (timestamp: number) => {
        if(!timestamp) {
            return '';
        }

        const date = new Date(timestamp);
        const options: Intl.DateTimeFormatOptions = { month: 'long'};
        const month = date.toLocaleString('pt-BR', options);
        return `${month}/${date.getFullYear()}`;
    }

    const daysBetween = (startDate: number, endDate: number = new Date().getTime()) => {
        return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    }

    const hoursBetween = (startDate: number, endDate: number = new Date().getTime()) => {
        return Math.ceil((endDate - startDate) / (1000 * 60 * 60));
    }

    const daysBetweenWithText = (startDate: number, endDate: number = new Date().getTime()) => {
        const numberOfDays = daysBetween(startDate, endDate);
        return `${numberOfDays} dia${numberOfDays > 1 ? 's' : ''}`;
    }

    const hoursBetweenWithText = (startDate: number, endDate: number = new Date().getTime()) => {
        const numberOfHours = hoursBetween(startDate, endDate);
        return `${numberOfHours} hora${numberOfHours > 1 ? 's' : ''}`;
    }

    const numberToLocalCurrency = (value: number) => {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(value);
    }

    return {
        convertToLocalDateTime,
        convertToLocalDate,
        convertToLocalMonthYear,
        daysBetween,
        daysBetweenWithText,
        numberToLocalCurrency,
        hoursBetween,
        hoursBetweenWithText
    }
}