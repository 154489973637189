import http from "@/http"
import { Billing } from "@/interfaces/Billing";
import { Report } from "@/interfaces/Report";
import Structure from "@/interfaces/Structure";
import { ref } from "vue";

export const useTenants = (tenant?: string) => {
    const tenants = ref<Structure[]>([]);
    const billings = ref<Billing[]>([]);
    const sensors = ref<Structure[]>([]);
    const reports = ref<Report[]>();

    const getReports = async () => {
        let search = '';
        if(tenant) {
            search = `/tenant/${tenant}`;
        }

        const resposta = await http.smartCity.get(`/report${search}`);
        reports.value = resposta.data['data'];
    }

    const getTenants = async () => {
        const resposta = await http.smartCity.get('/structures/tenants');
        tenants.value = resposta.data['data'];
        if(tenant) {
            tenants.value = tenants.value.filter(t => t.id == tenant);
        }
    }

    const getBillingInfo = async (startDate: number, endDate: number) => {
        let id = '';
        if(tenant) {
            id = `/${tenant}`;
        }

        const resposta = await http.smartCity.post(`/billing/period${id}`, {
            startDate,
            endDate
        });

        billings.value = resposta.data['data'] || [];
    }

    

    return {
        getTenants,
        getBillingInfo,
        getReports,
        tenants,
        billings,
        sensors,
        reports
       
    }
}