
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import OperatorItem from './OperatorItem.vue';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faBan);

export default defineComponent({
    name: 'SelectOperator',
    emits: ['onSelect'],
    components: {
        OperatorItem
    },
    props: {
        selectedOption: {
            type: String
        }
    },
    setup(props, { emit }) {
        const selectOperator = ref<HTMLDivElement | null>(null);
        const optionOperators = ref<HTMLDivElement | null>(null);

        const showOptions = () => {
            if(optionOperators.value) {
                optionOperators.value.style.display = 'block';
            }
        };

        const closeOptions = () => {
            if(optionOperators.value) {
                optionOperators.value.style.display = 'none';
            }
        };

        const showDropDown = () => {
            if(!selectOperator.value || !optionOperators.value) {
                return;
            }

            const x = selectOperator.value.offsetLeft;
            const y = selectOperator.value.offsetTop + selectOperator.value.getBoundingClientRect().height;
            const width = selectOperator.value.getBoundingClientRect().width;

            optionOperators.value.style.left = `${x}px`;
            optionOperators.value.style.top = `${y}px`;
            optionOperators.value.style.width = `${width}px`;

            showOptions();
        };

        const select = (operator: string) => {
            closeOptions();
            emit('onSelect', operator);
        };

        const windowClick = (event: MouseEvent) => {
            if(!selectOperator.value?.contains(event.target as Node)) {
                closeOptions();
            }
        };

        onMounted(() => {
            window.addEventListener('click', windowClick);
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowClick);
        });

        return {
            selectOperator,
            optionOperators,
            showDropDown,
            select
        }
    }
});
