import http from "@/http";
import Endpoint from "@/interfaces/Endpoint";
import { State } from "@/store";
import { GET_ENDPOINTS } from "@/store/action-types";
import { DEFINE_ENDPOINTS, LOGOUT } from "@/store/mutation-types";
import { Module } from "vuex";

export interface EndpointState {
    endpoints: Endpoint[];
}

export const endpoint: Module<EndpointState, State> = {
    mutations: {
        [DEFINE_ENDPOINTS](state, data: { endpoints: Endpoint[], gateways: string[], isMaster: boolean }) {
            if(data.isMaster) {
                state.endpoints = data.endpoints;
            } else {
                if(data.gateways) {
                    state.endpoints = data.endpoints.filter(endpoint => data.gateways.includes(endpoint.gateway));
                } else {
                    state.endpoints = [];
                }
            }
        },
        [LOGOUT](state) {
            state.endpoints = [];
        }
    },
    actions: {
        async [GET_ENDPOINTS]({ commit }, data: { gateways: string[], isMaster: boolean }) {
            const resposta = await http.smartCity.get('/endpoints/all');
            commit(DEFINE_ENDPOINTS, { endpoints: resposta.data['data'], gateways: data.gateways, isMaster: data.isMaster });
        }
    }
}