import http from "@/http";
import { Alarm, HistoryAlarm } from "@/interfaces/Alarm";
import { State } from "@/store";
import { GET_ALARM_HISTORY } from "@/store/action-types";
import { ACK_ALARM, ADD_ALARM, DEFINE_ALARMS, DEFINE_HISTORY_ALARMS, LOGOUT } from "@/store/mutation-types";
import { Module } from "vuex";

export interface AlarmState {
    alarms: Alarm[],
    historyAlarms: HistoryAlarm[]
}

export const alarm: Module<AlarmState, State> = {
    mutations: {
        [DEFINE_ALARMS](state, alarms: Alarm[]) {
            state.alarms = alarms;
        },
        [DEFINE_HISTORY_ALARMS](state, alarms: HistoryAlarm[]) {
            state.historyAlarms = alarms;
            state.historyAlarms.sort((a, b) => {
                if(a.endedAt && b.endedAt) {
                    const aDate = Date.parse(a.endedAt);
                    const bDate = Date.parse(b.endedAt);
                    return bDate - aDate;
                }

                const aDate = Date.parse(a.startedAt);
                const bDate = Date.parse(b.startedAt);
                return bDate - aDate;
            });
        },
        [ADD_ALARM](state, alarm: Alarm) {
            if(!state.alarms) {
                state.alarms = [];
            }

            const index = state.alarms.findIndex(a => a.id == alarm.id);

            if(index == -1) {
                state.alarms.push(alarm);
                deleteAlarm(state, alarm.id);
            }
        },
        [ACK_ALARM](state, alarmId) {
            state.alarms = state.alarms.map(alarm => {
                if(alarm.id == alarmId) {
                    alarm.ack = true;
                }

                return alarm;
            });

            unackAlarm(state, alarmId);
        },
        [LOGOUT](state) {
            state.alarms = [];
        }
    },
    actions: {
        async [GET_ALARM_HISTORY]({ commit }, structure: string) {
            let find = '';
            if(structure != 'root') {
                find = `/structure/${structure}`;
            }

            const resposta = await http.smartCity.get(`/alarm${find}`);
            commit(DEFINE_HISTORY_ALARMS, resposta.data['data']);
        }
    }
}

const deleteAlarm = (state: AlarmState, alarmId: string) => {
    const timeout = setTimeout(() => {
        state.alarms = state.alarms.filter(alarm => alarm.id != alarmId);
        clearInterval(timeout);
    }, 30000);
};

const unackAlarm = (state: AlarmState, alarmId: string) => {
    const timeout = setTimeout(() => {
        const alarm = state.alarms.find(a => a.id == alarmId);

        const timeout = setTimeout(() => {
            if(alarm) {
                alarm.ack = false;
            }

            clearTimeout(timeout);
        }, 5000);
    })
};