
import { defineComponent, ref, watch } from 'vue';
import Modal from '@/components/Modal.vue';
import { useStore } from '@/store';
import { CREATE_USER } from '@/store/action-types';
import SelectTenant from './SelectTenant.vue';
import { usePermissions } from '@/composables/UsePermissions';
export default defineComponent({
    name: 'ModalCreateUser',
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal,
        SelectTenant
    },
    emits: ['onClose'],
    setup(props, { emit }) {
        const store = useStore();

        const show = ref(false);
        const selectedTenant = ref<string | undefined>();
        const { isMaster } = usePermissions();
        
        const formUser = ref({
            name: '',
            username: '',
            email: '',
            role: []
        });
        const formRoles = ref<string>('');

        const close = () => {
            selectedTenant.value = undefined;
            emit('onClose');
        };

        const closeModalUser = () => {
            formUser.value = {
                name: '',
                username: '',
                role: [],
                email: ''
            }
            close();
        };

        const saveUser = (user: any, role: string) => {
            var tenant = selectedTenant.value;
            if(!isMaster.value) {
                tenant = store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.tenant;
            }
            
            user.role.push({
                name: 'SMART_CITY',
                data: {
                    role: role,
                    tenant: tenant,
                } 
            });
            store.dispatch(CREATE_USER, user);
            closeModalUser();
        };

        const selectTenant = (id: string) => {
            selectedTenant.value = id;
        };

        return {
            formUser,
            formRoles,
            saveUser,
            closeModalUser,
            show,
            selectTenant,
            selectedTenant,
            isMaster
        }
    }
})
