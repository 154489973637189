import { Store } from "vuex";
import { State } from "..";
import { DEFINE_USERS, SET_ROLE, SET_TOKEN, SET_USER } from "../mutation-types";
import User, { Role } from "@/interfaces/User";
import { Token } from "../modules/users";
import { DEFINE_GATEWAYS } from "../mutation-types";
import Structure from "@/interfaces/Structure";

const plugin = (store: Store<State>) => {
    store.subscribe((mutation, state) => {
        if(mutation.type == 'SET_TOKEN') {
            const token = JSON.stringify(mutation.payload);
            sessionStorage.setItem('token', token);
        }

        if(mutation.type == 'SET_USER') {
            const user = JSON.stringify(mutation.payload);
            sessionStorage.setItem('user', user);
        }

        if(mutation.type == 'SET_ROLE') {
            const role = JSON.stringify(mutation.payload);
            sessionStorage.setItem('role', role);
        }

        if(mutation.type == 'DEFINE_GATEWAYS') {
            const userTenant = JSON.stringify(mutation.payload);
            sessionStorage.setItem('userTenant', userTenant);
        }

        if(mutation.type == 'LOGOUT') {
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('role');
            sessionStorage.removeItem('userTenant');
        }
        
    });

    const sessionUser = sessionStorage.getItem('user');
    const sessionToken = sessionStorage.getItem('token');
    const sessionRole = sessionStorage.getItem('role');
    const sessionUserTenant = sessionStorage.getItem('userTenant');
    if(sessionUser && sessionToken && sessionRole && sessionUserTenant) {
        const user: User = JSON.parse(sessionUser);
        const token: Token = JSON.parse(sessionToken);
        const role: Role = JSON.parse(sessionRole);
        const userTenant: Structure = JSON.parse(sessionUserTenant);
        store.commit(SET_TOKEN, token);
        store.commit(SET_USER, user);  
        store.commit(SET_ROLE, role);  
        store.commit(DEFINE_GATEWAYS, userTenant);
    }
}

export { plugin }