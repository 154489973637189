import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["sensor-value", { 'half-size': _ctx.size == 'half-size'}])
    }, _toDisplayString(_ctx.sensorValue), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["sensor-unit", { 'half-size': _ctx.size == 'half-size'}])
    }, _toDisplayString(_ctx.sensorType()), 3)
  ], 64))
}