
import { defineComponent, onMounted, onUnmounted } from 'vue';

export default defineComponent({
    name: 'PermissionError',
    emits: ['onDismiss'],
    setup(props, { emit }) {
        var timeout: number;
        const dismissMessage = () => {
            emit('onDismiss');
        }

        onMounted(() => {
            timeout = setTimeout(() => {
                dismissMessage();
            }, 5000);
        })

        onUnmounted(() => {
            clearTimeout(timeout);
        })

        return {
            dismissMessage
        }
    }
});
