
import Structure from "@/interfaces/Structure";
import { State, useStore } from "@/store";
import { GET_USER_TENANT } from "@/store/action-types";
import { computed, ref, watch, watchEffect } from "vue";

export const usePermissions = () => {
    const store = useStore();
    const roles = computed(() => store.state.user.userRole);
    const root = computed(() => {
        if(isMaster.value) {
            return 'root';
        }

        return roles.value.find(role => role.name == 'SMART_CITY')?.data?.structure || '';
    });
    const hasAccess = computed(() => roles.value?.length > 0 ? roles.value?.filter(role => role.name == 'SMART_CITY').length > 0 : false);
    const isMaster = computed(() => roles.value?.length > 0 ? roles.value?.filter(role => role.data?.role == 'MASTER').length > 0 : false);
    const isAdmin = computed(() => roles.value?.length > 0 ? roles.value?.filter(role => role.name == 'ADMIN').length > 0 : false);
    const isSmartAdmin = computed(() => roles.value?.length > 0 ? roles.value?.filter(role => role.data?.role == 'ADMIN').length > 0 : false);
    const hasPermission = computed(() => roles.value?.length > 0 ? roles.value?.filter(role => role.name == 'SMART_CITY' && Object.prototype.hasOwnProperty.call(role, 'data') && Object.prototype.hasOwnProperty.call(role.data, 'structure')).length > 0 : false);
    const gateways = computed(() => store.state.structure.gateways);
    const hasReadPermission = computed(() => {
        const smartRole = store.state.user.userRole.find(role => role.name == 'SMART_CITY');
        if(smartRole?.data?.role == 'MASTER') {
            return true;
        }

        const permissions = smartRole?.data?.permissions;

        if(permissions) {
            return permissions.includes('read') || permissions.includes('all');
        }

        return false;
    });
    const hasCreatePermission = computed(() => {
        const smartRole = store.state.user.userRole.find(role => role.name == 'SMART_CITY');
        if(smartRole?.data?.role == 'MASTER') {
            return true;
        }

        const permissions = smartRole?.data?.permissions;

        if(permissions) {
            return permissions.includes('create') || permissions.includes('all');
        }

        return false;
    });
    const hasUpdatePermission = computed(() => {
        const smartRole = store.state.user.userRole.find(role => role.name == 'SMART_CITY');
        if(smartRole?.data?.role == 'MASTER') {
            return true;
        }

        const permissions = smartRole?.data?.permissions;
        if(permissions) {
            return permissions.includes('update') || permissions.includes('all');
        }

        return false;
    });
    const hasDeletePermission = computed(() => {
        const smartRole = store.state.user.userRole.find(role => role.name == 'SMART_CITY');
        if(smartRole?.data?.role == 'MASTER') {
            return true;
        }

        const permissions = smartRole?.data?.permissions;
        if(permissions) {
            return permissions.includes('delte') || permissions.includes('all');
        }

        return false;
    });
    // const isAdmin = (): boolean => {
    //     if(roles.value && roles.value.length > 0) {
    //         return roles.value.filter(role => role.data?.role == 'MASTER').length > 0;
    //     }

    //     return false;
    // }

    // const root = (): string => {
    //     return roles.value.find(role => role.name == 'SMART_CITY')?.data?.structure || '';
    // }

    // const hasAccess = (): boolean => {
    //     if(roles.value && roles.value.length > 0) {
    //         return roles.value.filter(role => role.name == 'SMART_CITY').length > 0;
    //     }

    //     return false;
    // }

    const tenant = () => {
        store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.tenant;
    }

    // const gateways = async (): Promise<string[]> => {
    //     const tenant: Structure = await store.dispatch(GET_USER_TENANT, store.state.user.userRole.find(role => role.name == 'SMART_CITY')?.data?.tenant);
    //     console.log('Tenant: ', tenant);
    //     return tenant?.gateways || [];
    // }


    return {
        root,
        isAdmin,
        hasAccess,
        hasPermission,
        isMaster,
        isSmartAdmin,
        gateways,
        hasReadPermission,
        hasCreatePermission,
        hasUpdatePermission,
        hasDeletePermission
    }
}