import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-647a6830"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "windowMessage",
  class: "window-message"
}
const _hoisted_2 = { class: "message-header" }
const _hoisted_3 = { class: "message-body" }
const _hoisted_4 = { class: "icon-text" }
const _hoisted_5 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("article", {
      ref: "windowMessage",
      class: _normalizeClass(["message is-small", _ctx.alarmType(_ctx.alarm.type)])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_ctx.alarm.name), 1),
        _createElementVNode("button", {
          class: "delete",
          "aria-label": "delete",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeAlarm(_ctx.alarm.id)))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.alarm.message), 1),
        _createElementVNode("p", null, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-location-dot" })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.alarm.location), 1)
          ])
        ])
      ])
    ], 2)
  ], 512))
}