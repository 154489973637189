
import { defineComponent } from 'vue';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationTriangle);

export default defineComponent({
    name: 'TriggerType',
    props: {
        type: {
            type: String,
            require: true
        }
    },
    setup(props) {
        const alarmType = () => {
            if(props.type == 'alarm') {
                return 'Alarme';
            } else {
                return 'Advertência';
            }
        }

        return {
            alarmType
        }
    }
});
