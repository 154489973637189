import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.option == 'eq')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: "fa-solid fa-equals"
        }))
      : _createCommentVNode("", true),
    (_ctx.option == 'neq')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: "fa-solid fa-not-equal"
        }))
      : _createCommentVNode("", true),
    (_ctx.option == 'gt')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 2,
          icon: "fa-solid fa-greater-than"
        }))
      : _createCommentVNode("", true),
    (_ctx.option == 'lt')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 3,
          icon: "fa-solid fa-less-than"
        }))
      : _createCommentVNode("", true),
    (_ctx.option == 'gte')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 4,
          icon: "fa-solid fa-greater-than-equal"
        }))
      : _createCommentVNode("", true),
    (_ctx.option == 'lte')
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 5,
          icon: "fa-solid fa-less-than-equal"
        }))
      : _createCommentVNode("", true)
  ]))
}