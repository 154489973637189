
import { useStore } from '@/store';
import { GET_TENANTS } from '@/store/action-types';
import { computed, defineComponent } from 'vue';
import Table from '@/components/Table.vue';

export default defineComponent({
    name: 'List',
    components: {
        Table
    },
    setup() {
        const store = useStore();
        store.dispatch(GET_TENANTS);
        const tenants = computed(() => store.state.tenant.tenants);

        return {
            tenants
        }
    }
});
