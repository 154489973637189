
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'Form',
    setup() {
        const router = useRouter();

        return {
            router
        }
    }
    
});
