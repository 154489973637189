import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6afadeb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  ref: "optionSensors",
  class: "sensors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SensorItem = _resolveComponent("SensorItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "selectSensor",
      class: "select-sensor",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDropDown && _ctx.showDropDown(...args)))
    }, [
      (!_ctx.selectedSensor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Escolha..."))
        : (_openBlock(), _createBlock(_component_SensorItem, {
            key: 1,
            sensor: _ctx.selectedSensor
          }, null, 8, ["sensor"]))
    ], 512),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sensors, (sensor) => {
        return (_openBlock(), _createBlock(_component_SensorItem, {
          key: sensor.id,
          sensor: sensor,
          onClick: ($event: any) => (_ctx.select(sensor))
        }, null, 8, ["sensor", "onClick"]))
      }), 128))
    ], 512)
  ], 64))
}