import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17b50ce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  ref: "optionOperators",
  class: "option-operators"
}
const _hoisted_5 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_OperatorItem = _resolveComponent("OperatorItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "selectOperator",
      class: "select-operator",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDropDown && _ctx.showDropDown(...args)))
    }, [
      (!_ctx.selectedOption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-ban" })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_OperatorItem, { option: _ctx.selectedOption }, null, 8, ["option"])
          ]))
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.select('')))
      }, [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-ban" })
        ])
      ]),
      _createVNode(_component_OperatorItem, {
        option: "AND",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select('AND')))
      }),
      _createVNode(_component_OperatorItem, {
        option: "OR",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.select('OR')))
      })
    ], 512)
  ], 64))
}