import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67bf3930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "date-field" }
const _hoisted_6 = { class: "date-field" }
const _hoisted_7 = {
  ref: "activeOption",
  class: "active-option"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "trigger-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickOnTrigger && _ctx.clickOnTrigger(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["icon", { alarm: _ctx.trigger.type == 'alarm' }])
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-triangle-exclamation" })
      ], 2),
      _createTextVNode(" " + _toDisplayString(_ctx.trigger.name), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-message" })
        ]),
        _createTextVNode(_toDisplayString(_ctx.trigger.message), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-rectangle-list" })
        ]),
        _createTextVNode(_toDisplayString(_ctx.trigger.description), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.createdAt), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.modifiedAt), 1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, [
        (_ctx.active)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "fa-solid fa-square-check"
            }))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: "fa-regular fa-square"
            }))
      ])
    ], 512)
  ]))
}