
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
    name: 'Table',
    props: {
        options: {
            type: Array as PropType<object[]>,
            required: true
        }
    },
    setup(props) {
        const keys = ref(['']);
        const values = ref([] as object);
        if(props.options?.length > 0) {
            keys.value = Object.keys(props.options[0]);
            values.value = props.options.map(option => option);
            
        }

        return {
            keys,
            values
        }
    }
    
    
});
