
import { computed, defineComponent } from 'vue';
import TitleBar from '@/components/TitleBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import EndpointCard from '@/components/EndpointCard.vue';
import { useStore } from '@/store';
import { GET_ENDPOINTS } from '@/store/action-types';
import { usePermissions } from '@/composables/UsePermissions';

export default defineComponent({
    name: 'Endpoints',
    components: {
        TitleBar,
        PageHeader,
        EndpointCard
    },
    setup() {
        const store = useStore();

        const { isMaster } = usePermissions();

        store.dispatch(GET_ENDPOINTS, { gateways: store.state.structure.gateways, isMaster: isMaster.value });

        return {
            endpoints: computed(() => store.state.endpoint.endpoints)
        }
    }
});
