import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    class: "date-picker",
    placeholder: "Escolha o mês",
    "minimum-view": "month",
    "maximum-view": "month",
    format: " MMMM/yyyy",
    language: "pt",
    onChangedMonth: _ctx.handleChangedMonth,
    value: _ctx.selectedDate
  }, null, 8, ["onChangedMonth", "value"]))
}