import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dc2aaf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "endpoint-data" }
const _hoisted_3 = { class: "endpoint-model" }
const _hoisted_4 = { class: "model-title" }
const _hoisted_5 = { class: "text-icon" }
const _hoisted_6 = { class: "model-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TitleCard = _resolveComponent("TitleCard")!
  const _component_EndpointItem = _resolveComponent("EndpointItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleCard, {
      title: _ctx.endpoint.name
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tower-broadcast" })
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-tag" })
          ]),
          _createTextVNode(" Model ")
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.endpoint.model), 1)
      ]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-uplink",
        text: "Uplink",
        value: _ctx.endpoint.uplink
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-satellite-dish" })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-actv-mode",
        text: "Actv Mode",
        value: _ctx.endpoint.activation_mode
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-bullseye" })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-datarate",
        text: "Datarate",
        value: _ctx.endpoint.datarate
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-gauge" })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-rssi",
        text: "RSSI",
        value: _ctx.endpoint.rssi
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-signal" })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-snr",
        text: "Snr",
        value: _ctx.endpoint.snr
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-align-center",
            rotation: "90"
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_EndpointItem, {
        class: "endpoint-extpwr",
        text: "Ext Pwr",
        value: _ctx.endpoint.ext_pwr
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-plug" })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}