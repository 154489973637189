import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7891cae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = { class: "modal-icon" }
const _hoisted_3 = { class: "modal-card-title" }
const _hoisted_4 = { class: "close-button" }
const _hoisted_5 = { class: "modal-card-body" }
const _hoisted_6 = { class: "modal-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { 'is-active': _ctx.isActive }])
  }, [
    _createElementVNode("div", {
      class: "modal-background",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["modal-card", { large: _ctx.size == 'large', medium: _ctx.size == 'medium'}])
    }, [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("button", {
            class: "delete",
            "aria-label": "close",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
      ]),
      _createElementVNode("footer", _hoisted_6, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ])
    ], 2)
  ], 2))
}