
import Structure from '@/interfaces/Structure';
import { useStore } from '@/store';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'SensorLastMessage',
    props: {
        structure: {
            type: Object as PropType<Structure>,
            required: true
        }
    },
    setup(props) {
        const store = useStore();

        return {
            lastMessage: computed(() => {
                if(store.state.sensor.sensors.length > 0) {
                    const date = new Date(store.state.sensor.sensors.find(sensor => sensor.id == props.structure.sensor)!.timestamp * 1000);
                    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
                }

                return '';
            })
        }
    }
})
