import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28d7fec4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-icon-text" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { ref: "mapIcon" }
const _hoisted_4 = {
  key: 0,
  ref: "removeSensor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SensorIcon = _resolveComponent("SensorIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "structure-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cardClick(_ctx.structure!, $event.target)))
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          (_ctx.structure?.type != 'sensor')
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "item-icon",
                icon: "fa-solid fa-folder",
                size: "2x"
              }))
            : (_openBlock(), _createBlock(_component_SensorIcon, {
                key: 1,
                type: _ctx.structure?.sensorType || '',
                size: "2x"
              }, null, 8, ["type"]))
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.structure?.name), 1)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_3, [
        (_ctx.structure?.file)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "fa-solid fa-map-location-dot"
            }))
          : _createCommentVNode("", true)
      ], 512)
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.structure?.createdBy), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.getCreationDate), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.getModificationDate), 1),
    _createElementVNode("div", null, [
      (_ctx.structure?.type == 'sensor' && (_ctx.isMaster || _ctx.isSmartAdmin))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash-can" })
          ], 512))
        : _createCommentVNode("", true)
    ])
  ]))
}