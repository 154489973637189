
import Sensor from '@/interfaces/Sensor';
import { computed, defineComponent, PropType } from 'vue';
import SensorIcon from '../SensorIcon.vue';

export default defineComponent({
    name: 'SensorSelectCard',
    props: {
        sensor: {
            type: Object as PropType<Sensor>,
            required: true
        }
    },
    components: {
        SensorIcon
    },

    setup(props) {
        return {
            value: computed(() => {
                if(typeof props.sensor.value == 'number') {
                    if(props.sensor.type == 'corrente') {
                        return props.sensor.value.toFixed(4) + ' ' + props.sensor.unit;
                    }

                    return props.sensor.value.toFixed(2) + ' ' +  props.sensor.unit;
                }

                return props.sensor.value;
            })
        }
    }
});
