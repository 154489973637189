
import Structure from '@/interfaces/Structure';
import { computed, defineComponent, PropType } from 'vue';
import SensorIcon from './SensorIcon.vue';
import SensorValue from './sensor/SensorValue.vue';
import SensorConnection from './sensor/SensorConnection.vue';
import SensorLastMessage from './sensor/SensorLastMessage.vue';
import { faLocationDot, faLink, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useStore } from '@/store';
import { structure } from '@/store/modules/structures';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faLocationDot, faLink, faCalendarDays);

export default defineComponent({
    name: 'MonitorCard',
    components: {
        SensorIcon,
        SensorValue,
        SensorConnection,
        SensorLastMessage
    },
    props: {
        structure: {
            type: Object as PropType<Structure>,
            required: true
        },
        isListMode: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore();
        const { gateways, isMaster } = usePermissions();

        const getFullLocation = (structure: Structure | undefined): string => {
            if(structure?.parent) {
                let s = getFullLocation(store.state.structure.structures.find(s => s.id == structure.parent));

                if(structure.type != 'sensor') {
                    s += '/' + structure.name;
                }

                return s;
            }

            return 'root';
        }

        return {
            location: computed(() => store.state.structure.structures.find(structure => structure.id == props.structure.parent)?.name || 'root'),
            getFullLocation,
            gateways,
            isMaster
        }
    }
});
