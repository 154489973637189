
import { Trigger } from '@/interfaces/Trigger';
import { computed, defineComponent, PropType, ref } from 'vue';
import { faTriangleExclamation, faRectangleList, faMessage, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { trigger } from '@vue/reactivity';
import { propsToAttrMap } from '@vue/shared';
import { useStore } from '@/store';
import { UPDATE_TRIGGER } from '@/store/action-types';
library.add(faTriangleExclamation, faRectangleList, faMessage, faSquare, faSquareCheck);

export default defineComponent({
    name: 'TriggerCard',
    emits: ['on-trigger-click', 'on-trigger-activate'],
    props: {
        trigger: {
            type: Object as PropType<Trigger>,
            required: true
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const activeOption = ref<HTMLDivElement>();
        const active = ref(props.trigger.active);

        const getLocalDateTime = (isoDate: string) => {
            const date = new Date(Date.parse(isoDate));
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        };

        const clickOnTrigger = (event: MouseEvent) => {
            const target = event.target as HTMLDivElement;
            if(!activeOption.value?.contains(target)) {
                emit('on-trigger-click', props.trigger);
            } else {
                active.value = !active.value;
                const trigger = {
                    ...props.trigger,
                    active: active.value
                }
                
                store.dispatch(UPDATE_TRIGGER, trigger);
            }
            
        };

        return {
            createdAt: computed(() => getLocalDateTime(props.trigger.createdAt)),
            modifiedAt: computed(() => getLocalDateTime(props.trigger.modifiedAt)),
            clickOnTrigger,
            activeOption,
            active
        }
    }
});
