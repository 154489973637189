import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      class: _normalizeClass(["icon", { alarm: _ctx.type == 'alarm' }])
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-exclamation-triangle" })
    ], 2),
    _createTextVNode(" " + _toDisplayString(_ctx.alarmType()), 1)
  ]))
}