
import { Billing } from '@/interfaces/Billing';
import { useFunctions } from '@/composables/UseFunctions';
import { PropType, computed, defineComponent } from 'vue';
import { billing } from '@/store/modules/billing';
export default defineComponent({
    name: 'TenantBillingCard',
    props: {
        billing: {
            type: Object as PropType<Billing>,
            required: true
        },
        monthDate: {
            type: Date
        }
    },
    setup(props) {
        const { convertToLocalDateTime, hoursBetweenWithText } = useFunctions();

        const monthStartDate = (monthDate: Date) => {
            const date = new Date(monthDate);
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date.getTime();
        }

        const monthEndDate = (monthDate: Date) => {
            const date = new Date(monthDate);
            date.setDate(1);
            date.setMonth(date.getMonth() + 1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setTime(date.getTime() - 1000);
            return date.getTime();
        };

        return {
            convertToLocalDateTime,
            hoursBetweenWithText,
            sensorStartDate: computed(() => {
                return Math.max(monthStartDate(props.monthDate!), props.billing.createdAt);
            }),
            sensorEndDate: computed(() => {
                if(props.billing.deletedAt) {
                    return Math.min(monthEndDate(props.monthDate!), props.billing.deletedAt, new Date().getTime());
                } 

                return Math.min(monthEndDate(props.monthDate!), new Date().getTime());
            })
        }
    }
});
