import http from "@/http";
import Structure from "@/interfaces/Structure";
import Tenant from "@/interfaces/Tenant";
import { State } from "@/store";
import { GET_TENANTS } from "@/store/action-types";
import { DEFINE_TENANTS, LOGOUT } from "@/store/mutation-types";
import { Module } from "vuex";

export interface TenantState {
    tenants: Structure[]
}

export const tenant: Module<TenantState, State> = {
    mutations: {
        [DEFINE_TENANTS](state, tenants: Structure[]) {
            state.tenants = tenants;
        },
        [LOGOUT](state) {
            state.tenants = [];
        }
    },
    actions: {
        async [GET_TENANTS]({ commit }) {
            const resposta = await http.smartCity.get(`/structures/parent/root`);
            commit(DEFINE_TENANTS, resposta.data['data']);
        }
    }
}