
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faCloudSunRain, faWifi, faTemperatureHalf, faSnowflake, faTv, faPlus, faGear, faTowerBroadcast, faUser, faShieldHalved, faBell, faFileInvoiceDollar, faSatelliteDish } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { usePermissions } from '@/composables/UsePermissions';
library.add(faHome, faCloudSunRain, faWifi, faTemperatureHalf, faSnowflake,
    faTv, faPlus, faGear, faTowerBroadcast, faUser, faShieldHalved, faBell,
    faFileInvoiceDollar, faSatelliteDish);

export default defineComponent({
    name: 'LeftMenu',
    emits: ['new-structure', 'new-sensor', 'new-user', 'new-trigger'],
    setup(props, { emit }) {
        const store = useStore();
        const route = useRoute();
        const isOptions = ref(false);
        const menuAcessos = ref<HTMLDivElement | null>(null);

        const { isAdmin, root, hasCreatePermission, isMaster, isSmartAdmin } = usePermissions();

        const button = ref(null);

        const showButtonOptions = () => {
            isOptions.value = !isOptions.value;
        };

        const newStructure = () => {
            emit('new-structure');
        };

        const newSensor = () => {
            emit('new-sensor');
        };

        const newUser = () => {
            emit('new-user');
        };

        const newTrigger = () => {
            emit('new-trigger');
        };

        const windowListener = (e: MouseEvent) => {
            if(e.target instanceof Element) {
                let element: Element = e.target
                if(element.classList.contains('is-new-button')) {
                    return;
                }

                isOptions.value = false;
            }
        }

        onMounted(() => {
            window.addEventListener('click', windowListener);

            if(store.state.user.user && menuAcessos.value) {
                if(isAdmin.value) {
                    menuAcessos.value.style.display = 'block';
                }
            }
        });

        onUnmounted(() => {
            window.removeEventListener('click', windowListener);
        })

        return {
            button,
            isOptions,
            newSensor,
            newStructure,
            newUser,
            newTrigger,
            showButtonOptions,
            menuAcessos,
            route,
            root,
            hasCreatePermission,
            isMaster,
            isSmartAdmin
        }
    }
});
