
import Structure from '@/interfaces/Structure';
import { PropType, defineComponent, computed, onMounted } from 'vue';
import SensorIcon from './SensorIcon.vue';
import SensorValue from './sensor/SensorValue.vue';
import { useStore } from '@/store';

export default defineComponent({
    name: 'SensorMap',
    props: {
        structure: {
            type: Object as PropType<Structure>,
            required: true
        }
    },
    components: {
        SensorIcon,
        SensorValue
    },
    setup(props) {
        const store = useStore();
        const sensor = computed(() => store.state.sensor.sensors?.find(sensor => sensor.id == props.structure.sensor))

        onMounted(() =>{
            console.log('Structure: ', props.structure);
        });
        
        return {
            sensor
        }
    }
});
